import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { get_all_brand_names, get_brand_image, get_home_image } from '../../../Services/APIService';
import axios from 'axios';
import TrendingLayout from '../Trending/TrendingLayout';
import BulkBrandLayout from './BulkBrandLayout';


export default function BulkProductTable() {

    const [value, setValue] = React.useState(0);
    const [Mantra, setMantra] = useState([])
    const [JeawellChair, setJeawellChair] = useState([])
    const [JeawellTable, setJeawellTable] = useState([])
    const [Pratica, setPratica] = useState([])
    const [Data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    const getAllData = () => {
        axios({
            method: 'GET',
            url: get_all_brand_names,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setData(res.data.data);
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }

    useEffect(()=>{
        getAllData();
    },[])

    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                { Data && Data.map((key, index) => (
                    <Tab
                      key={index}
                      label={key.brandName}
                      sx={{
                        bgcolor: value === index && '#00bca4',
                        border: value === index && '1px solid silver',
                        borderRadius: value === index && '4px',
                        textTransform: 'none',
                      }}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
            </Box>

            {Data && Data.map((key, index) => (
                <TabPanel key={index} value={value} index={index}>
                  <BulkBrandLayout BrandName={key.brandName} />
                </TabPanel>
              ))}

        </Container>
    )
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { get_all_confirmed_orders, get_all_placed_orders, update_order_status } from '../../Services/APIService';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Autocomplete, Button, Container, Grid, TextField } from '@mui/material';
import { orderTable } from '../../Variables/Variables';
import UpdateOrderDialog from './UpdateOrderDialog';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

export default function ConfirmedOrder() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [orders, setOrders] = useState([]);
    const [search, setSearch] = useState('');

    const [openUpdateOrderDialog, setopenUpdateOrderDialog] = useState(false);
    const [OrderId, setOrderId] = useState('');
    const [UserId, setUserId] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Payment, setPayment] = useState('');
    const [AllData, setAllData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const sendData = new FormData()
        sendData.append('orderId', '')
        axios({
            method: "POST",
            url: get_all_confirmed_orders,
            data:sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setOrders(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openDialog = (i) => {
        navigate('/dashbrdlayout/updatedorderDetails', { state: { orderId: i.orderId, userId: i.userId } });
    }



    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'Processed Orders'} />
            </Box>

            <Container>
                <Box mt={2} py={4}  >
                    <Grid container>
                        <TableContainer sx={{ border: '1px solid silver' }} >

                            <Table>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                        {
                                            orderTable.map((i, index) => {
                                                return (
                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {i.title}
                                                            </Box>
                                                            <Box>{
                                                                i.filter &&
                                                                <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                            }
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                    {
                                        orders.filter((data) => FilterData(data, search, {
                                            searchFeildOne: data.orderId,
                                            searchFeildTwo: data.userId,
                                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                            return (
                                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.orderId}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.userOrderId}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.userId}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.firstName} {i.lastName}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.email}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.orderCost}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.orderTime}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.paymentOption}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                                        <Button sx={{ width: 150 }} variant='contained' color='warning' onClick={() => openDialog(i)}>View</Button>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={orders.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            </Container>

            <ToastContainer />
        </Box>
    );
}
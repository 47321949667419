import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import {  expense_graph, get_allproducts, get_expense_category,} from '../../../Services/APIService';
import { Chart } from "react-google-charts";
import { Typography } from '@mui/material';


export default function BalanceGraph() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [ExpenseCategory, setExpenseCategory] = useState([]);


  const getAllExpense  = () =>{
    const sendData = new FormData()
    sendData.append('checkBox', '');
  axios({
    method: "POST",
    url: expense_graph,
    data: sendData
  }).then(res => {
    if (res.data.error) {
      setMessage(res.data.message)
      setOpen(true)
      setStatus(false)
      setColor(false)
    } else {
      setOpen(true)
      setMessage(res.data.message)
      setStatus(true)
      setColor(true)
      setExpenseCategory(res.data.data);
    }
  }).catch(err => {
    console.log(err)
  });
}

 useEffect(() => {
   getAllExpense();
 }, []);


  const data = [
    ["Expense", "Amount"],
    ...ExpenseCategory?.map((i) => [i.expenseCategory, i.totalAmount]),
  ];



  const options = {
    is3D: true,
    legend:{textStyle: {color: '#616e80'}},
  };



  return (

    <Box>
      <Typography textAlign='left'>**This Section Reserved for Future Use. Currently Displaying Sample Data.**</Typography>
    <Chart
    chartType="PieChart"
    data={data}
    options={options}
    width={"100%"}
    height={"400px"}
  />
    </Box>

  );
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container , Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ReturnRequestList from './ReturnRequestList';
import ReturnSale from './ReturnSale';
import ListOfReturn from './LsitedOfReturn';

export default function ReturnOrderTab() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p:{lg:2 , md:2 , sm:1 , xs:0} }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

  return (
      <Container>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
              <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="success"
                  textColor="inherit"
                  aria-label="full width tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
              >
                  <Tab
                      label="Return & Refund Requests"
                      sx={{
                          bgcolor: value === 0 && '#00bca4',
                          border: value === 0 && '1px solid silver',
                          borderRadius: value === 0 && '4px',
                          textTransform: 'none'
                      }}
                      {...a11yProps(0)} />
                  <Tab
                      label="Return an Order"
                      sx={{
                          bgcolor: value === 1 && '#00bca4',
                          border: value === 1 && '1px solid silver',
                          borderRadius: value === 1 && 1,
                          textTransform: 'none'
                      }}
                      {...a11yProps(1)}
                  />
                  <Tab
                      label="All Returned Items"
                      sx={{
                          bgcolor: value === 2 && '#00bca4',
                          border: value === 2 && '1px solid silver',
                          borderRadius: value === 2 && 2,
                          textTransform: 'none'
                      }}
                      {...a11yProps(1)}
                  />
              </Tabs>
          </Box>

          {/* Orders items */}

          <TabPanel value={value} index={0} >
              <ReturnRequestList />
          </TabPanel>

          {/* Payment  */}
          <TabPanel value={value} index={1}>
              <ReturnSale />
          </TabPanel>

          <TabPanel value={value} index={2}>
              <ListOfReturn />
          </TabPanel>

      </Container>
  )
}

import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { add_expense_category, delete_expense_sub_category, get_expense_category, get_expense_sub_category, update_expense_category, update_expense_sub_category } from '../../Services/APIService';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ExpenseCategroyDialog({ setExpenseCategoryId, ExpenseCategoryId ,  getAllExpense, setMessage, setOpen, setStatus, setColor, openExpenseDialog, setOpenExpenseDialog }) {

    const [expenseCategory, setexpenseCategory] = useState('');
    const [expenseSubCategory, setexpenseSubCategory] = useState('');
    const [storedexpenseSubCategory, setstoredexpenseSubCategory] = useState([]);
    const [storedexpenseSubCategoryFromApi, setstoredexpenseSubCategoryfromAPI] = useState([]);
    const [ExpenseSubCategory, setExpenseSubCategory] = useState([]);
    const [expenseSubCategoryId, setexpenseSubCategoryId] = useState(null);
    const [editMode, setEditMode] = useState(false)
    const [editId , setEditId] = useState('');

    const handleClose = () =>{
        setOpenExpenseDialog(false);
        setExpenseCategoryId('');
        setexpenseCategory('');
        setExpenseCategoryId('');
        setstoredexpenseSubCategory([])
    }

        const getExpenseById = () => {
            if (ExpenseCategoryId !== '' && ExpenseCategoryId !== null){
        const sendData = new FormData();
                sendData.append('expenseCategoryId', ExpenseCategoryId);
        axios({
        method: "POST",
        url: get_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setexpenseCategory(res.data.data.expenseCategory);
        }
        }).catch(err => {
        console.log(err)
        });
        }
        else{
        setexpenseCategory('')
        }
        }

        useEffect(() => {
        getExpenseById();
        }, [ExpenseCategoryId]);


        const getAllExpenseSubCategory = () => {
            if(ExpenseCategoryId !== null && ExpenseCategoryId !== '' && ExpenseCategoryId !== undefined){
            const sendData = new FormData();
            sendData.append('expenseCategoryId', ExpenseCategoryId);
            axios({
                method: "POST",
                url: get_expense_sub_category,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setstoredexpenseSubCategoryfromAPI(res.data.data);
                    setExpenseSubCategory(res.data.data);
                }
            }).catch(err => {
                console.log(err)
            });
        }
        }
    
        useEffect(() => {
            getAllExpenseSubCategory();
        }, [ExpenseCategoryId]);


        const handleAddExpense = () => {
        if (ExpenseCategoryId !== '' && ExpenseCategoryId !== null) {
        const sendData = new FormData();
        sendData.append('expenseCategory', expenseCategory);
        sendData.append('expenseCategoryId', ExpenseCategoryId);
        axios({
        method: "POST",
        url: update_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        getAllExpense();
        setexpenseCategory('');
        setExpenseCategoryId('');
        }
        }).catch(err => {
        console.log(err)
        });
        }
        else {
        const sendData = new FormData();
        sendData.append('expenseCategory', expenseCategory);
        sendData.append('subCategory', JSON.stringify(storedexpenseSubCategory));
        axios({
        method: "POST",
        url: add_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        getAllExpense();
        }
        }).catch(err => {
        console.log(err)
        });
        }}


        const storesubCate = () =>{
            const newObject = {'expenseSubCategory': expenseSubCategory}
            setstoredexpenseSubCategory((prev) => [...prev, newObject]);
            setexpenseSubCategory('');
        }



        const handleDelete = (id) =>{
            const sendData = new FormData();
            sendData.append('expenseSubCategoryId', id);
            axios({
            method: "POST",
            url:delete_expense_sub_category,
            data: sendData
            }).then(res => {
            if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            getAllExpenseSubCategory();
        }
    }).catch(err => {
        console.log(err)
    });
}



  return (
      <Dialog onClose={handleClose} open={openExpenseDialog} fullWidth>
          <DialogTitle>
           NEW EXPENSE CATEGORY
          </DialogTitle>
          <DialogContent>
              <Box p={3}>
                  <TextField
                      id="outlined-basic"
                      size='small'
                      label='Expense Category'
                      value={expenseCategory}
                      fullWidth
                      onChange={(e) => setexpenseCategory(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                      variant="outlined"
                  />
              </Box>
              <Box p={2}>

            {
            ExpenseCategoryId === '' ? (
              <Stack spacing={3} p={3}>
                <Box display='flex' justifyContent='center' flexDirection='row' gap={3}>
                <TextField
                id="outlined-basic"
                size='small'
                label='Expense Sub-Category'
                value={expenseSubCategory}
                fullWidth
                onChange={(e) => setexpenseSubCategory(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                variant="outlined"
                />
                <Box>
                <Button variant='contained' color='primary' onClick={storesubCate}
                    sx={{ borderRadius: 0, boxShadow: 0, fontWeight: 500 }} ><AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }} /> ADD</Button>
                </Box>
                </Box>

                <Stack spacing={1}>
                    {
                        storedexpenseSubCategory?.map((i)=>{
                            return(
                                <Typography>{i.expenseSubCategory}</Typography>
                            )
                        })
                    }
                </Stack>
              </Stack>
              )
            :
                <Grid container spacing={4}>
                {
                storedexpenseSubCategoryFromApi?.map((i)=>{
                return(
                <Grid item xs={12} md={6}  key={i.expenseSubCategoryId}>
                    <Box display='flex' justifyContent='start' flexDirection='row' gap={1} textAlign='left'>
                    <Typography>{i.expenseSubCategory}</Typography>
                    <DeleteIcon color='error' sx={{verticalAlign:'middle'}} onClick={()=>handleDelete(i.expenseSubCategoryId)} />
                    </Box>
                </Grid>
                 )})}
                </Grid>
            }
     </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
              <Button onClick={handleAddExpense} variant='outlined' color='success'>{ExpenseCategoryId !== '' && ExpenseCategoryId !== null ? 'UPDATE' : 'SAVE'}</Button>
          </DialogActions>
      </Dialog>
  )
}

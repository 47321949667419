import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid, Button, Card } from '@mui/material';
import { getmainproducts, get_allproducts, get_all_deleted_products, BaseImageAPI, get_purchase, purchase_bill, get_channel_sale_order, channel_invoice, get_all_return_product } from '../../Services/APIService';
import { DeletedproductsTable, PurchaseListTable, ReturnListTable, SaleListTable, orderTable, productsTable } from '../../Variables/Variables';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function ListOfReturn() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [ReturnList, setReturnList] = useState([]);
    const [search, setSearch] = useState('');
    const [currentReturnListId, setCurrentReturnListId] = useState(null);
    const navigate = useNavigate();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const sendData = new FormData()
        sendData.append('returnOrderId' , '')
        axios({
            method: "POST",
            url: get_all_return_product,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setReturnList(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }, []);

    const GotoDetails = (id) => {
        navigate('/dashbrdlayout/retrundetailview', { state: { id: id } });
    }

    return (
        <Box py={1}>
            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>

                        <Grid container>
                            <TableContainer sx={{ border: '1px solid silver' }} >

                                <Table size='small' bgcolor='#eff8f2'>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                            {
                                                ReturnListTable.map((i, index) => {
                                                    return (
                                                        <TableCell sx={{
                                                            textAlign: 'center', color: 'white', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',
                                                        }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {i.title}
                                                                </Box>
                                                                <Box>{
                                                                    i.filter &&
                                                                    <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                                }
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>


                                    <TableBody>

                                        {
                                            ReturnList.filter((data) => FilterData(data, search, {
                                                searchFeildOne: data.orderNum,
                                                searchFeildTwo: data.customerName,
                                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                                return (
                                                    <TableRow key={i.returnOrderId} sx={{
                                                        borderBottom: '1px solid silver',
                                                        '& .MuiTableCell-root': {
                                                            height: '20px',
                                                        }
                                                    }}>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.orderNum}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.orderDate}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.returnDate}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.userId}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.customerName}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.total}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>  <Button sx={{ width: 150 }} variant='contained' color='warning' onClick={()=>GotoDetails(i.returnOrderId)}>View</Button></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={ReturnList.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage='Rows'
                            />
                        </Grid>

            </Box>
        </Box>
    );
}
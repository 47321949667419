import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { delete_expense } from '../../Services/APIService';
import { Typography, Box, Stack } from '@mui/material';
import axios from 'axios';

export default function ExpensesListDeleteDialog({amount , setAmount,  setexpenseCategoryName, expenseCategoryName, setExpenseListName, ExpenseListName,  setExpenseListId, ExpenseListId, getAllExpense, setMessage, setOpen, setStatus, setColor, openExpenseListDeleteDialog, setOpenExpenseListDeleteDialog }) {

    const handleClose = () => {
        setOpenExpenseListDeleteDialog(false);
        setExpenseListId('');
        setExpenseListName('');
        setexpenseCategoryName('');
        setAmount('');
    }

    const handleDeleteExpense = () => {
        if (ExpenseListId !== '' && ExpenseListId !== null) {
            const sendData = new FormData();
            sendData.append('expenseId', ExpenseListId);
            axios({
                method: "POST",
                url: delete_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                    getAllExpense();

                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    return (
        <div>
            <Dialog
                open={openExpenseListDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"ARE YOU SURE THAT YOU WANNA DELETE THIS EXPENSE PERMANENTLY?"}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Expense Name: {ExpenseListName}</Typography>
                        <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Expense Category: {expenseCategoryName}</Typography>
                        <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Amount: {amount}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='primary'>CLOSE</Button>
                    <Button onClick={handleDeleteExpense} variant='outlined' autoFocus color='error'>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import axios from 'axios';
import { user_signin } from '../../Services/APIService';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        Shopztrend
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function AdminLoginPage() {

    const navigate = useNavigate();
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = React.useState();
      const [color, setColor] = useState();
      const[email, setEmail] = useState('');
      const[password, setPassword]= useState('');
      const [errMsg , setErrMsg] = useState('');
      const [rememberMe, setRememberMe] = useState(false);

      
      const handleSubmit = (e) => {
        e.preventDefault();
        if(email !== "" && password !== ""){
        const serverData = new FormData()
        serverData.append('email', email);
        serverData.append('password',password);
        axios({
          method: "POST",
          url:user_signin,
          data: serverData,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setErrMsg(res.data.message)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setErrMsg(res.data.message)
                localStorage.setItem('Adminauth', true);
                localStorage.setItem('AdminRole', res.data.data.roles);
                localStorage.setItem('AdminUserName', res.data.data.adminName);
                navigate(`dashbrdlayout/dashboard`)
                localStorage.setItem('AdminUserId', res.data.data.adminId);
                 localStorage.setItem('access', JSON.stringify({
                  dashboardView: res.data.data.dashboardView,
                  addCategoryView: res.data.data.addCategoryView,
                  updateCategoryView: res.data.data.updateCategoryView,
                  addProductView: res.data.data.addProductView,
                  updateProductView: res.data.data.updateProductView,
                  allProductView: res.data.data.allProductView,
                  archiveProductView: res.data.data.archiveProductView,
                  purchaseListView: res.data.data.purchaseListView,
                  addPurchaseView: res.data.data.addPurchaseView,
                  vendorView: res.data.data.vendorView,
                  webOrderView: res.data.data.webOrderView,
                  channelOrderView: res.data.data.channelOrderView,
                  returnRefundView: res.data.data.returnRefundView,
                  expenseView: res.data.data.expenseView,
                  expenseCategoryView: res.data.data.expenseCategoryView,
                  customerView: res.data.data.customerView,
                  inquiryView: res.data.data.inquiryView,
                  homePageView: res.data.data.homePageView,
                  trendingView: res.data.data.trendingView,
                  saleView: res.data.data.saleView,
                  brandsView: res.data.data.brandsView,
                  accessView: res.data.data.accessView,
                  authorizationView: res.data.data.authorizationView,
                  reportView: res.data.data.reportView,
                  wholeSaleView:res.data.data.wholeSaleView, 
                  bankStatementView:res.data.data.bankStatementView,
                   cashFlowView:res.data.data.cashFlowView, 
                   incomeStatementView:res.data.data.incomeStatementView,
                    balanceSheetView:res.data.data.balanceSheetView
                }));
            }
        }).catch(err => {
            console.log(err)
        });
      }
      else{
          setErrMsg('Please Enter Email and Password')
      }
      }



  return (
    <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
           <Container component="main" maxWidth="xs">
          <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
          <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color:'#ffff'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Stack textAlign='left' spacing={2}>
              <Box>
              <Typography>Email</Typography>
            <TextField
              margin="normal"
              required
              sx={{width: {xs:250, sm: 300, md: 500 , lg:500 }}}
              id="email"
              name="email"
              type='email'
              autoComplete="email"
              onChange={(e)=>setEmail(e.target.value)}
            />
            </Box>
            <Box>
            <Typography>Password</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e)=>setPassword(e.target.value)}
              helperText={errMsg}
            />
            </Box>
            </Stack>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor:'#faa634', ':hover':{bgcolor:'#316C31'} }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" style={{color:'#262626'}} to='resetpassword'>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
           
          </Box>
        </Box>
        <Copyright sx={{ mt: 6 }} />
          </Container>
        </ul>
    </div >
  );
}

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { Container, Grid, Button, Card, TextField, Stack } from '@mui/material';
import {  add_expense_sub_category, get_allproducts, get_expense, get_expense_category, update_expense_category, update_expense_sub_category,} from '../../Services/APIService';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpenseCategoryDelete from './ExpenseCategoryDelete';
import Heading from '../../Components/Heading/Heading';
import ExpenseCategroyDialog from './ExpenseCategroyDialog';
import AddIcon from '@mui/icons-material/Add';
import ExpensesListAddDialog from './ExpensesListAddDialog';
import ExpensesListDeleteDialog from './ExpensesListDeleteDialog';
import ExpensesUpdateDialog from './ExpenseUpdateDialog';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}


function ExpenseRow(props){
  const { row  , i ,subCategoryData ,getAllExpense , setMessage, setOpen, setStatus, setColor} = props;

  const [openSubAccord, setOpenSubAccord] = React.useState(false);
  const [newSubCategory , setnewSubCategory] = useState('');
  const [newCategory , setnewCategory] = useState('');
  const [editMode , setEditMode] = useState(false);
  const [editId , setEditId] = useState('');
  const [SelectedDelete , setSelectedDelete] = useState('');

  const [openDialog , setOpenDialog] = useState(false);
  const [dialogDataName , setDialogDataName] = useState('')
  const [dialogDataId , setDialogDataId] = useState('')


  const [ExpenseList, setExpenseList] = useState([]);
  const [search, setSearch] = useState('');
  const [openExpenseListDeleteDialog, setOpenExpenseListDeleteDialog] = useState('');
  const [ExpenseListId, setExpenseListId] = useState('');
  const [ExpenseListName, setExpenseListName] = useState('');
  const [expenseCategoryName, setexpenseCategoryName] = useState('');
  const [amount , setAmount] = useState('');
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);
  const [openExpenseListDialog, setOpenExpenseListDialog] = useState('');


const EditExpense = (id) => {
    setOpenExpenseListDialog(true);
    setExpenseListId(id);
}


const DeleteExpense = (id, name, category, bill) => {
    setOpenExpenseListDeleteDialog(true);
    setExpenseListId(id);
    setExpenseListName(name);
    setexpenseCategoryName(category);
    setAmount(bill)
}

  return (
    <React.Fragment>

    <ExpensesUpdateDialog setExpenseListId={setExpenseListId} getAllExpense={getAllExpense} ExpenseListId={ExpenseListId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseListDialog={openExpenseListDialog} setOpenExpenseListDialog={setOpenExpenseListDialog} />
    <ExpensesListDeleteDialog amount={amount} setAmount={setAmount} expenseCategoryName={expenseCategoryName} setexpenseCategoryName={setexpenseCategoryName} setExpenseListId={setExpenseListId} setExpenseListName={setExpenseListName} ExpenseListName={ExpenseListName} getAllExpense={getAllExpense} ExpenseListId={ExpenseListId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseListDeleteDialog={openExpenseListDeleteDialog} setOpenExpenseListDeleteDialog={setOpenExpenseListDeleteDialog} />

      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left">{i + 1}</TableCell>
        <TableCell align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenSubAccord(!openSubAccord)}
          >
            {openSubAccord ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">{row}</TableCell>
        <TableCell align="left">{subCategoryData[row][0]?.totalSubExpenseAmount}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0 , paddingTop: 0 }} colSpan={6}>
          <Collapse in={openSubAccord} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases"  sx={{ whiteSpace: 'nowrap' , bgcolor:'#C1D8C3' }}>
                <TableHead  sx={{ whiteSpace: 'nowrap' , bgcolor:'#B6C4B6' }}>
                  <TableRow>
                  <TableCell align="left" sx={{fontWeight:600}}>Date</TableCell>
                    <TableCell align="left" sx={{fontWeight:600}}>Comments</TableCell>
                    <TableCell align="left" sx={{fontWeight:600}}>Amount</TableCell>
                    <TableCell align="left" sx={{fontWeight:600}}>Recurring</TableCell>
                    <TableCell align="left" sx={{fontWeight:600}}>Recurring Type</TableCell>
                    <TableCell align="center" sx={{fontWeight:600}}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {subCategoryData[row]?.map((historyRow) => {
              
                  return(
                    <TableRow key={historyRow.expenseSubCategoryId}>
                       <TableCell component="th" scope="row">
                      {historyRow.expenseDate}                       
                      </TableCell>
                      <TableCell>
                      {historyRow.expenseDetails}                       
                      </TableCell>
                      <TableCell align="left">{historyRow.expenseAmount}</TableCell>
                      <TableCell align="left">{historyRow.recurring}</TableCell>
                      <TableCell align="left">{historyRow.recurringType}</TableCell>
                <TableCell align="left">                              
                <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                <Button onClick={() => EditExpense(historyRow.expenseId)} variant="contained" sx={{ height: 25, width: 80, fontWeight: 600, opacity: 0.9, fontSize: 12 }} ><DriveFileRenameOutlineIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> EDIT</Button>
                <Button onClick={() => DeleteExpense(historyRow.expenseId, historyRow.expenseDetails, historyRow.expenseCategory , historyRow.expenseAmount )} variant="contained" sx={{ height: 25, width: 90, fontWeight: 600, opacity: 0.9, fontSize: 12 }} color='error'><DeleteForeverIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> DELETE</Button>
                </Box>
                </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




function Row(props) {

  const { row  , ind , getAllExpense , setMessage, setOpen, setStatus, setColor , openExpenseListDialog, setOpenExpenseListDialog} = props;
  const [openAccord, setOpenAccord] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{ind + 1}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenAccord(!openAccord)}
          >
            {openAccord ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.expenseCategory}</TableCell>
        <TableCell align="center">{row.totalAmount}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0 , paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAccord} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases"  sx={{ whiteSpace: 'nowrap' , bgcolor:'#EEF0E5' }}>
                <TableHead  sx={{ whiteSpace: 'nowrap' , bgcolor:'#B6C4B6' }}>
                  <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell />
                  <TableCell sx={{fontWeight:600}} align="left">SubCategory</TableCell>
                  <TableCell  sx={{fontWeight:600}} align="left">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(row.subCategory)?.map((historyRow , index) => (
                <ExpenseRow openExpenseListDialog={openExpenseListDialog} setOpenExpenseListDialog={setOpenExpenseListDialog} row={historyRow} subCategoryData={row.subCategory} getAllExpense={getAllExpense} i={index} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
                ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




export default function NewExpenseList() {


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [ExpenseCategory, setExpenseCategory] = useState([]);
    const [ExpenseList, setExpenseList] = useState([]);
    const [search, setSearch] = useState('');
    const [openExpenseListDeleteDialog, setOpenExpenseListDeleteDialog] = useState('');
    const [ExpenseListId, setExpenseListId] = useState('');
    const [ExpenseListName, setExpenseListName] = useState('');
    const [expenseCategoryName, setexpenseCategoryName] = useState('');
    const [amount , setAmount] = useState('');
    const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);
    const [openExpenseListDialog, setOpenExpenseListDialog] = useState('');
    const[yearnumber , setYearNumber] = useState('');
    const textFieldYearRef = useRef();

    const [showMonth , setShowMonth ] = useState(false);
    const [showYear , setShowYear ] = useState(true);
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const[currentMode , setCurentMode] = useState('Yearly');
    const textFieldRef = useRef();
    const textFieldFromRef = useRef();

    const getAllExpense  = () =>{
        const sendData = new FormData();
        axios({
          method: "POST",
          url: get_expense,
        
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });
      }
     
       useEffect(() => {
         getAllExpense();
       }, []);

       const getFilteredYearData = (year) =>{
        const sendData = new FormData();
        sendData.append('expenseYear', year !== undefined ? year : '')
        axios({
          method: "POST",
          url: get_expense,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
       }


       const getFilteredMonthData = (year) =>{
        if( textFieldRef?.current?.value !== undefined &&  textFieldRef?.current?.value !== '' &&  textFieldRef?.current?.value !== null){
        const sendData = new FormData();
        sendData.append('checkBox', 'Monthly');
        sendData.append('fromDate', textFieldFromRef?.current?.value !== undefined ? textFieldFromRef?.current?.value : '');
        sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : '');
        axios({
          method: "POST",
          url: get_expense,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
      }
       }
   



       const handleOpenDialog = () => {
        setOpenExpenseListDialog(true);
    }

         useEffect(() => {
          const calculateTotalExpenseAmount = () => {
              const totalAmount = ExpenseCategory.reduce((acc, item) => acc + parseFloat(item.totalAmount), 0);
              setTotalExpenseAmount(parseFloat(totalAmount).toFixed(2));
          };
  
          calculateTotalExpenseAmount();
      }, [ExpenseCategory]);


      const ChangeMode = (mode) =>{
        setCurentMode(mode);
        if (mode === 'Yearly'){
          setCurentMode('Yearly');
         setShowYear(true);
        setShowMonth(false);
        getFilteredYearData()
        }
        else if (mode === 'Monthly'){
          setCurentMode('Monthly')
          setShowMonth(true);
          setShowYear(false);
          getFilteredMonthData();
        }
      }




  return (
    <Box p={3}>
    
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box p={3}>
              <Heading title={'Expense List'} />
          </Box>

          <ExpensesListAddDialog setExpenseListId={setExpenseListId} getAllExpense={getAllExpense} ExpenseListId={ExpenseListId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseListDialog={openExpenseListDialog} setOpenExpenseListDialog={setOpenExpenseListDialog} />
    
      <Box mb={3} mt={1}>
      <Grid container>
      <Grid item xs={12}>
      <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
      <Box display='flex' flexDirection='row' gap={2}>
      <Button variant='contained' color='success' onClick={handleOpenDialog}
      sx={{ borderRadius: 0, boxShadow: 0, fontWeight: 600 }} ><AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }} /> NEW EXPENSE RECORD</Button>

      </Box>
      <Box px={2}>
      <Typography fontWeight={600}>Total : ${totalExpenseAmount}</Typography>
      </Box>
      </Box>
      </Grid>
    
      <Grid item xs={12} justifyContent='end' display='flex'>
      <Grid container spacing={3}>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box sx={{cursor:'pointer'}} display='flex' flexDirection='row' gap={3}>
       <Typography sx={{color: currentMode === 'Monthly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Monthly')}>Monthly</Typography>
       <Typography sx={{color: currentMode === 'Yearly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Yearly')}>Yearly</Typography>
    </Box>
      </Grid>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box>
      {
        showMonth &&
        <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'row', xs:'column'}} gap={2}>
        <TextField
        type="date"
        id="start" 
        size='small'
        name="trip-start"
        inputRef={textFieldFromRef}
        onChange={(e) => {
        setFromDate(e.target.value);
        getFilteredMonthData();
        }}
        label='From date'
        required
        InputLabelProps={{
          shrink: true,
        }}
          />
    
          <TextField
          type="date"
          id="end" 
          size='small'
          name="trip-end"
          inputRef={textFieldRef}
          onChange={(e) => {
          setToDate(e.target.value);
            getFilteredMonthData();
          }}
          label='To date'
          required
          InputLabelProps={{
            shrink: true,
          }}
            />
       </Box>
      }
      {
        showYear &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        views={['year']}
        label="Year"
        openTo='year'
        slotProps={{ textField: { size: 'small' } }}
        inputRef={textFieldYearRef}
        dateFormat="yyyy"
        onChange={(newValue) => {
        const date = new Date(newValue);
        const YearDate = date.getFullYear();
        setYearNumber(YearDate);
        getFilteredYearData(YearDate);
        }}
        renderInput={(params) => <TextField 
        {...params} 
        helperText={null}
        inputProps={{
        ...params.inputProps,
        readOnly: true
        }} size='small' />}
        />
        </LocalizationProvider>
      }
      </Box>
      </Grid>
      </Grid>
      </Grid>
    
      </Grid>
      </Box>


    <TableContainer component={Card} sx={{boxShadow:6}}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ whiteSpace: 'nowrap' , bgcolor:'#B6C4B6' }}>
          <TableRow>
          <TableCell sx={{fontWeight:600}}>S.NO</TableCell>
          <TableCell />
            <TableCell sx={{fontWeight:600}}>Expense Category</TableCell>
            <TableCell align="center" sx={{fontWeight:600}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ExpenseCategory?.map((row , index) => (
            <Row openExpenseListDialog={openExpenseListDialog} setOpenExpenseListDialog={setOpenExpenseListDialog} key={row.name} row={row} ind={index} getAllExpense={getAllExpense} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}

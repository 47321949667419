import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material'
import { BaseImageAPI, delete_product } from '../../Services/APIService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function RemoveDialogBox({ handleClick, setOpen, setMessage , setColor , setStatus , openDialog, setOpenDialog, id, name, mainImage}) {

    const navigate = useNavigate();

    const handleClose = () => {
        setOpenDialog(false);
        navigate(-1);
    };


    const handleDelete = () => {
        if (id !== '') {
            const serverData = new FormData()
            serverData.append('productId', id)
            axios({
                method: "POST",
                url: delete_product,
                data: serverData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true);
                    handleClose();
                    handleClick();
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    return (
        <div>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{fontSize:17}}>
                    {"ARE YOU SURE THAT YOU WANT TO DELETE THE PRODUCT PERMANENTLY ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box display='flex' flexDirection='column' gap={2}> 
                            <Typography component='span'>Product</Typography> <img style={{ width: 100, height: 100 }} src={`${BaseImageAPI}${mainImage}`} alt='Image' />
                            <Typography>Selected ProductId : <Typography component='span' color='#333' fontWeight={600}> {id}</Typography>  </Typography>
                            <Typography> Selected ProductName : <Typography component='span' color='#333' fontWeight={600}> {name}</Typography>  </Typography>
                        </Box>                                             
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete}>YES</Button>
                    <Button onClick={handleClose} autoFocus>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
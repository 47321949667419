import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import BigSlider from './Homepage/BigSlider';
import Arriving from './Homepage/Arriving';
import Offers from './Homepage/Offers';
import LikedCategory from './Homepage/LikedCategory';
import Brands from './Homepage/Brands';
import { get_home_image, get_wholesale_trendz_banner, getallcategory } from '../../Services/APIService';
import axios from 'axios';

export default function WebsiteTab() {

    const [value, setValue] = React.useState(0);
    const [HomeSilder, setHomeSlider] = useState([])
    const [OfferSection, setOfferSection] = useState([])
    const [BrandSection, setBrandSection] = useState([])
    const [CategorySection, setCategorySection] = useState([])
    const [ArrivingSection, setArrivingSection] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [heading , setHeading] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

  const getAllData = () =>{
    const sendData = new FormData()
    sendData.append('homePageImageId', '');
      axios({
          method: 'POST',
          url: get_home_image,
          data:sendData,
      }).then(res => {
          if (!res.data.error) {
              setMessage(res.data.message)
              setArrivingSection(res.data.data.ArrivingSoon)
              setCategorySection(res.data.data.Trendz)
              setBrandSection(res.data.data.WholesaleBrands)
              setOfferSection(res.data.data.ShoppingOffers)
              setHomeSlider(res.data.data.HomePageSlider)
              setOpen(true)
              setStatus(true)
              setColor(true)
          } else {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
          }
      }).catch(err => {
          alert('Oops something went wrong ' + err)
      });
  }

    useEffect(() => {
        getAllData();
    }, [])


    useEffect(() => {
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const CategoryOptions = categoryList.map((i) => {
        const obj = {
            'label': i.categoriesName,
            'id': i.categoryId,
        }
        return obj;
    });

    const getBannerText = () => {
        const sendData = new FormData()
        sendData.append('bannerId', 3);
        axios({
          method: 'POST',
          url: get_wholesale_trendz_banner,
          data: sendData,
        }).then(res => {
            if (!res.data.error) {
                setHeading(res.data.data.bannerHeading);
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }
   
    useEffect(()=>{
        getBannerText();
    },[])

    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Slider Section"
                        sx={{
                            bgcolor: value === 0 && '#00bca4',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                     <Tab
                        label="Layout 1"
                        sx={{
                            bgcolor: value === 1 && '#00bca4',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                        <Tab
                        label="Layout 2"
                        sx={{
                        bgcolor: value === 2 && '#00bca4',
                        border: value === 2 && '1px solid silver',
                        borderRadius: value === 2 && 2,
                        textTransform: 'none'
                        }}
                        {...a11yProps(2)}
                        />
                    <Tab
                        label="Layout 3"
                        sx={{
                            bgcolor: value === 3 && '#00bca4',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 && 3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(3)}
                    />

                    <Tab
                        label="Layout 4"
                        sx={{
                            bgcolor: value === 4 && '#00bca4',
                            border: value === 4 && '1px solid silver',
                            borderRadius: value === 4 && 4,
                            textTransform: 'none'
                        }}
                        {...a11yProps(4)}
                    />

                </Tabs>
            </Box>

           

            <TabPanel value={value} index={0} >
                <BigSlider HomeSilder={HomeSilder} getAllData={getAllData} setHomeSlider={setHomeSlider} heading={heading} setHeading={setHeading} />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Offers CategoryOptions={CategoryOptions} OfferSection={OfferSection} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Brands CategoryOptions={CategoryOptions} BrandSection={BrandSection} getAllData={getAllData} />
            </TabPanel>
           
            <TabPanel value={value} index={3}>
                <LikedCategory CategoryOptions={CategoryOptions} CategorySection={CategorySection} setCategorySection={setCategorySection} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={4}>
                <Arriving CategoryOptions={CategoryOptions} ArrivingSection={ArrivingSection} getAllData={getAllData} />
            </TabPanel>



        </Container>
    )
}

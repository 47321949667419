import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './Routes';
import { createTheme, ThemeProvider } from '@mui/material';


function App() {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Baloo Thambi 2'
      ].join(','),
    },
    Link: {
      fontFamily: [
        'Baloo Thambi 2'
      ].join(','),
    },
  });




  return (
    <ThemeProvider theme={theme}>
    <div className="App">
    <Router>
    <Routes />
    </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useRef } from 'react'
import { Box, Typography, Container, Grid, Rating, Card, CardMedia, Link, CardContent,  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Breadcrumbs, CardActionArea, Dialog, DialogContent,DialogActions, DialogTitle, DialogContentText, Stack, TextField, List, Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton, InputAdornment, Input, Divider } from '@mui/material'
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AddBox, EmailOutlined } from '@mui/icons-material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { add_Income_sheet, add_income_statement, get_Income_sheet, get_sales_expenses_for_income_statement } from '../../../Services/APIService';
import CustomTextField from '../../../Components/CustomTextField';
import SnackBar from '../../../Components/AdminDashBoardComps/Snackbar';


export default function IncomeSheet({setValue}) {

  const [IncomeSheetPeriod, setIncomeSheetPeriod] = useState(null);
  const [sales, setSales] = useState(0);
  const [otherProductionExpenses, setOtherProductionExpenses] = useState(0);
  const [totalCostOfSales, setTotalCostOfSales] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [grossMarginPercentage, setGrossMarginPercentage] = useState(0);
  const datePickerRef = useRef(null);
  const [inventory, setInventory] = useState(null);
  const [utilities, setUtilities] = useState(null);
  const [marketing, setMarketing] = useState(null);
  const [humanResource, setHumanResource] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [systemDevelopment, setSystemDevelopment] = useState(null);
  const [officeEquipments, setOfficeEquipments] = useState(null);
  const [customTaxes, setCustomTaxes] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [totalExpense, setTotalExpense] = useState(0);
  const [ebitda, setEbitda] = useState(0);
  const [interestExpense, setInterestExpense] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [netProfit, setNetProfit] = useState(0);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [financialYear, setFinancialYear] = useState(null);

  const handleDateChange = (date) => {
    setFinancialYear(date);
    const year = date.year(); 
    const nextYear = year + 1;
    const financialYearString = `${year}-${nextYear.toString()}`;
    setIncomeSheetPeriod(financialYearString);
  };
  


  const getallData = () =>{
    if(IncomeSheetPeriod !== null && IncomeSheetPeriod !== '' && IncomeSheetPeriod !== undefined){
    const sendData = new FormData()
    sendData.append('incomePeriod' , IncomeSheetPeriod)
    axios({
        method: "POST",
        url: get_sales_expenses_for_income_statement,
        data:sendData
    }).then(res => {
        if (!res.data.error) {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setSales(res.data.data.sales);
            // setTaxes(res.data.data.tax);
            
        setInventory(res.data.data.inventory);
        setUtilities(res.data.data.utilities);
        setMarketing(res.data.data.marketing);
        setHumanResource(res.data.data.humanResource);
        setInsurance(res.data.data.insurance);
        setLicenses(res.data.data.licenses);
        setSystemDevelopment(res.data.data.systemDevelopment);
        setOfficeEquipments(res.data.data.officeEquipments);
        setCustomTaxes(res.data.data.customTaxes);
        setShipping(res.data.data.shipping);

        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)     
        }
    }).catch(err => {
        console.log(err)
    });
  }
    }

    useEffect(()=>{
      getallData();
    },[IncomeSheetPeriod])


    useEffect(()=>{
      if(sales !== 0 && sales !== null && sales !== undefined){
      const parsedSales = parseFloat(sales) || 0;
      const parsedotherProductionExpenses = parseFloat(otherProductionExpenses) || 0;
      setGrossMargin((parsedSales - parsedotherProductionExpenses).toFixed(2));
      }
      else{
        setGrossMargin(0);
      }
    },[sales, otherProductionExpenses])


    useEffect(() => {
      if(sales !== 0 && sales !== null && sales !== undefined && grossMargin !== 0 && grossMargin !== null && grossMargin !== undefined){
      const parsedSales = parseFloat(sales) || 0;
      const parsedGrossMargin = parseFloat(grossMargin) || 0;
      const calculatedGrossMarginPercentage = ((parsedGrossMargin / parsedSales) * 100).toFixed(2);
      const formattedPercentage = `${calculatedGrossMarginPercentage}%`;
      setGrossMarginPercentage(formattedPercentage);
      }
      else{
        setGrossMarginPercentage(0)
      }
    }, [sales, grossMargin]);


    useEffect(() => {
      const parsedpayroll = parseFloat(inventory) || 0;
      const parsedmarketing = parseFloat(marketing) || 0;
      const parseddepreciation = parseFloat(shipping) || 0;
      const parsedutilities = parseFloat(utilities) || 0;
      const parsedinsurance = parseFloat(insurance) || 0;
      const parsedrent = parseFloat(systemDevelopment) || 0;
      const parsedpayrollTaxes = parseFloat(humanResource) || 0;
      const parsedsoftware = parseFloat(licenses) || 0;
      const parsedotherExpense = parseFloat(officeEquipments) || 0;
      const parsedleasedEquipment = parseFloat(customTaxes) || 0;
      const calculatedTotalExpense = (parsedpayroll + parsedmarketing + parseddepreciation + parsedutilities + parsedinsurance + parsedrent  + parsedpayrollTaxes + parsedsoftware +parsedotherExpense + parsedleasedEquipment).toFixed(2);
      setTotalExpense(calculatedTotalExpense);
    }, [inventory, marketing, shipping, utilities, insurance, systemDevelopment, humanResource, licenses, customTaxes, officeEquipments]);
    

    useEffect(()=>{
      if(sales !== null && sales !== undefined){
      const parsedSales = parseFloat(sales) || 0;
      const parsedotherProductionExpenses = parseFloat(otherProductionExpenses) || 0;
      const parsedtotalExpense = parseFloat(totalExpense) || 0;
      setEbitda((parsedSales - parsedotherProductionExpenses - parsedtotalExpense).toFixed(2));
      }
      else{
        setEbitda(0);
      }
    },[sales, otherProductionExpenses , totalExpense])
    

    useEffect(()=>{
      if( sales !== null && sales !== undefined){
      const parsedebitda = parseFloat(ebitda) || 0;
      const parsedinterestExpense = parseFloat(interestExpense) || 0;
      // const parsedtaxes = parseFloat(taxes) || 0;
      setNetProfit((parsedebitda - parsedinterestExpense).toFixed(2));
      }   
      else{
        setNetProfit(0);
      }
    },[ebitda, interestExpense, sales])


    const onSubmit = () => {
      if(IncomeSheetPeriod !== null && IncomeSheetPeriod !== '' && IncomeSheetPeriod !== undefined){
      const serverData = new FormData()
      serverData.append('incomePeriod', IncomeSheetPeriod);
      serverData.append('sales', sales);
      serverData.append('otherProductionExpenses', otherProductionExpenses);
      serverData.append('totalCostOfSales', totalCostOfSales);
      serverData.append('grossMargin', grossMargin);
      serverData.append('grossMarginPercentage', grossMarginPercentage);

      serverData.append('inventory', inventory);
      serverData.append('utilities', marketing);
      serverData.append('marketing', marketing);
      serverData.append('humanResource', humanResource);
      serverData.append('insurance', utilities);
      serverData.append('licenses', insurance);
      serverData.append('systemDevelopment', systemDevelopment);
      serverData.append('officeEquipments', officeEquipments);
      serverData.append('customTaxes', customTaxes);
      serverData.append('shipping', shipping);

      serverData.append('totalExpense', totalExpense);
      serverData.append('ebitda', ebitda);
      serverData.append('interestExpense', interestExpense);
      // serverData.append('taxes', taxes);
      serverData.append('netProfit', netProfit);
      axios({
          method: "POST",
          url:add_income_statement,
          data: serverData,
      }).then(res => {
          if (!res.data.error) {
            // setValue(2);
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setIncomeSheetPeriod(null);
            setSales(0);
            setOtherProductionExpenses(0);
            setTotalCostOfSales(0);
            setGrossMargin(0);
            setGrossMarginPercentage(0);
            setInventory(0);
            setMarketing(0);
            setShipping(0);
            setOfficeEquipments(0);
            setUtilities(0);
            setInsurance(0);
            setSystemDevelopment(0);
            setLicenses(0);
            setCustomTaxes(0);
            setHumanResource(0);
            setFinancialYear(null)
            setTotalExpense(0);
            setEbitda(0);
            setInterestExpense(0);
            datePickerRef.current.value = null;
            setNetProfit(0);
          } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(false)
          setColor(false)
          }
      }).catch(err => {
          console.log(err)
      });
    }
    else{
    setMessage('Choose a Period');
    setOpen(true)
    setStatus(false)
    setColor(false) 
  }
}

  return (
      <Box p={1}>

<SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Box component={Card} p={3} boxShadow={4}>
    <Grid container>
    <Stack spacing={4} textAlign='left'>

    <Box pt={1} pb={1}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'column', xs:'column'}} gap={1}>
    <DatePicker
    views={['year']}
    label="Select Year"
    openTo='Year'
    value={financialYear}
    onChange={handleDateChange}
    renderInput={(params) => <TextField {...params} helperText={null} />}
    />
            <TextField
          id="financial-year"
          label="Financial Year"
          value={IncomeSheetPeriod}
          inputRef={datePickerRef}
          sx={{mx:2}}
          InputProps={{
            readOnly: true,
             // Disable editing of the input field
          }}
          InputLabelProps={{
            shrink:true
          }}
        />
       </Box>
    </LocalizationProvider>
    </Box>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(A) Sales</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1A) Sales</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={sales}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2A) Other Production Expenses</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
        value={otherProductionExpenses}
    onChange={setOtherProductionExpenses}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(4A) Gross Margin</Typography>
    <Typography variant='caption'>(1A - 2A)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={grossMargin}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(5A) Gross Margin %</Typography>
    <Typography variant='caption'>(4A / 1A)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={grossMarginPercentage}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box textAlign='left'>
    <Typography variant='h6' color='#616e80' fontWeight={600}>(B) Expenses</Typography>
    </Box>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1B) Customs Duty/Tax</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={customTaxes}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2B) Human Resources Management</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={humanResource}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3B) Licenses and Permits</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={licenses}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(4B) Inventory</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={inventory}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(5B) Office Equipment/Office Supplies</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={officeEquipments}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(6B) Insurance</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={insurance}
    />
    </Grid>
    </Box>


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(7B) Marketing and Advertising</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={marketing}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(8B) Shipping and Logistics</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={shipping}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(9B) System and Development</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={systemDevelopment}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(10B) Warehouse/Rent/Utilities/Other Charges</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <TextField 
    size='small' 
    variant="outlined"
    value={utilities}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>(11B) Total Operating Expenses</Typography>
    <Typography variant='caption'>SUM (1B : 10B)</Typography>
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    value={totalExpense}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>

    <Stack spacing={3} borderBottom='1px solid silver' pb={2}>
    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(1C) Earnings Before Interest, Taxes, Depreciation and Amortization (EBITDA)</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={ebitda}
    onChange={setEbitda}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: "column" }}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(2C) Interest Expenses</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
     <CustomTextField
    value={interestExpense}
    onChange={setInterestExpense}
    validationRegex={/^[0-9]*\.?[0-9]*$/}
    errorMessage={"Only Numeric Values are Allowed"}
    />
    </Grid>
    </Box>

    {/* <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography>(3C) Taxes</Typography>
    </Grid>
     <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
    variant="outlined"
    value={taxes}
    />
    </Grid>
    </Box>

    <Box pt={1}>
    </Box> */}


    <Box display='flex' justifyContent='start' flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs:"column"}}>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <Typography variant='subtitle1' fontWeight={600}>Net Profit</Typography>
    <Typography variant='caption'>(1C + 2C )</Typography>
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} px={1}>
    <TextField 
    size='small' 
     value={netProfit}
    variant="outlined"
    />
    </Grid>
    </Box>
    </Stack>


    <Box>
    <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center'>
    <Button onClick={onSubmit} variant='contained' fullWidth   color='warning'>Submit</Button> 
    </Grid>
    </Box>

    </Stack>
    </Grid>
    </Box>


      </Box>
  )
}

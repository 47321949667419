import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { delete_vendor, get_vendor, update_vendor } from '../../Services/APIService';


export default function VendorDeleteDialog({ setVendorId, vendorId, getallVendorAPI, setMessage, setOpen, setStatus, setColor, setOpenDeleteDialog, openDeleteDialog }) {

    const [vendorName, setVendorName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');


    const handleClose = () => {
        setOpenDeleteDialog(false);
        getallVendorAPI();
        setVendorId('');
    }

    useEffect(() => {
        const sendData = new FormData();
        sendData.append('vendorId', vendorId)
        axios({
            method: "POST",
            url: get_vendor,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setVendorName(res.data.data.vendorName);
                setContactPerson(res.data.data.contactPerson);
                setMobile(res.data.data.mobile);
                setEmail(res.data.data.email);
            }
        }).catch(err => {
            console.log(err)
        });
    }, []);



    const handleClick = () => {
        const serverData = new FormData()
        serverData.append('vendorId', vendorId)
        axios({
            method: "POST",
            url: delete_vendor,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setVendorName('');
                setContactPerson('');
                setMobile('');
                setEmail('');
                getallVendorAPI();
                handleClose();
            }
        }).catch(err => {
            console.log(err)
        });
    }


    return (
        <Dialog onClose={handleClose} open={openDeleteDialog} fullWidth>
            <DialogTitle id="alert-dialog-title">
                {"ARE YOU SURE THAT YOU WANNA DELETE THIS VENDOR PERMANENTLY?"}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Vendor Name:  {vendorName}</Typography>
                    <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Contact Person:  {contactPerson}</Typography>
                    <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Email:  {email}</Typography>
                    <Typography variant='subtitle2' fontWeight={600} color='#616e80'>Mobile:  {mobile}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='primary'>CLOSE</Button>
                <Button variant='outlined' color='error' onClick={handleClick}>DELETE</Button>
            </DialogActions>
        </Dialog>
    )
}

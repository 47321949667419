import { Box, Typography, Card,  Grid } from '@mui/material'
import React, { useState } from 'react'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Heading from '../../Components/Heading/Heading';
import CategoryIcon from '@mui/icons-material/Category';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import BalanceIcon from '@mui/icons-material/Balance';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Groups2Icon from '@mui/icons-material/Groups2';
import PieChartIcon from '@mui/icons-material/PieChart';
import SavingsIcon from '@mui/icons-material/Savings';
import ReportDialog from './ReportDialog';
import { channel_sale_orders_excel_report, employees_excel_report, expenses_excel_report, get_expense_report, product_excel_report, profit_loss_report, purchases_excel_report, stocks_excel_report, suppliers_excel_report, web_orders_excel_report, whole_sale_orders_excel_report } from '../../Services/APIService';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Diversity2Icon from '@mui/icons-material/Diversity2';

export default function ExportReport() {

    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [data , setData] = useState([]);
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const[selectedReport, setSelectedReport]= useState('');


    const handleOpen = (Report) => {
    setOpenDialog(true);
    setSelectedReport(Report);
    }


    const getAllExpense = () => {
            const sendData = new FormData();
            sendData.append('fromDate', fromDate);
            sendData.append('toDate', toDate);
            axios({
                method: "POST",
                url: expenses_excel_report,
                data: sendData
            }).then(res => {
                if (!res.data.error) {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                
                    ExpenseExportToCSV(res.data.data);
                    setOpenDialog(false);
                    setSelectedReport('');
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                }
            }).catch(err => {
                console.log(err)
            });
    }

    const getAllWebOrders = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: web_orders_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllChannelOrders = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: channel_sale_orders_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllWholeSaleOrders = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: whole_sale_orders_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllProducts = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: product_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllStock = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: stocks_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllEmployee = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: employees_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllSupplier = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: suppliers_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const getAllPurchase = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: purchases_excel_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const getAllprofitlossreport = () => {
        const sendData = new FormData();
        sendData.append('fromDate', fromDate);
        sendData.append('toDate', toDate);
        axios({
            method: "POST",
            url: profit_loss_report,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                exportToCSV(selectedReport, res.data.data);
                setOpenDialog(false);
                setSelectedReport('');
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const exportToCSV = (name, data) => {

        const modifiedData = data.map((item) => {
            const modifiedItem = {};
            for (const key in item) {
              if (item.hasOwnProperty(key)) {
                const words = key.split(/(?=[A-Z])/); // Split by capital letters
                const capitalizedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Capitalize and join with a space
                modifiedItem[capitalizedKey] = item[key];
              }
            }
            return modifiedItem;
          });
          
        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(modifiedData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blob, name + fileExtension);
      };

      const ExpenseExportToCSV = (data) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        const wb = {
            Sheets: {},
            SheetNames: []
        };
    
        for (const category in data) {
            if (data.hasOwnProperty(category)) {
                // Replace illegal characters and limit length in category name
                let sheetName = category
                    .replace(/[^\w\s]/g, ' | ') // Remove characters that are not word characters or spaces
                    .replace(/\s+/g, '  ') // Replace consecutive spaces with a single space
                    .substring(0, 31);
    
                if (sheetName.length > 31) {
                    console.error(`Invalid sheet name: ${sheetName}`);
                }
    
                const sheetData = data[category].map((item) => {
                    const modifiedItem = {};
                    for (const prop in item) {
                        if (item.hasOwnProperty(prop)) {
                            const words = prop.split(/(?=[A-Z])/);
                            const capitalizedProp = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                            modifiedItem[capitalizedProp] = item[prop];
                        }
                    }
                    return modifiedItem;
                });
    
                const ws = XLSX.utils.json_to_sheet(sheetData);
                wb.Sheets[sheetName] = ws;
                wb.SheetNames.push(sheetName);
            }
        }
    
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blob, 'Expenses' + fileExtension);
    };
    
    


  return (
   <Box p={2}>

          <ReportDialog  setSelectedReport={setSelectedReport}
          getAllWholeSaleOrders={getAllWholeSaleOrders}
          getAllprofitlossreport={getAllprofitlossreport}
          getAllEmployee={getAllEmployee} getAllSupplier={getAllSupplier} getAllPurchase={getAllPurchase}
          getAllChannelOrders={getAllChannelOrders} getAllProducts={getAllProducts} getAllStock={getAllStock}
          setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} getAllExpense={getAllExpense} getAllWebOrders={getAllWebOrders} setOpenDialog={setOpenDialog} openDialog={openDialog} selectedReport={selectedReport} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />

          <Grid container spacing={1}>

          {
            /*  
              <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Box onClick={()=>handleOpen('Category')} color='#fff' component={Card} bgcolor='#00bca4'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center' >
                  <CategoryIcon sx={{fontSize:'65px' , opacity:0.8}} />
                  <Typography sx={{opacity:0.8}} variant='h5'>Category</Typography>
                  </Box>
                  <Box onClick={()=>handleOpen('Category')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>
              */
          }

            <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
            <Box onClick={()=>handleOpen('Product')} color='#fff' component={Card} bgcolor='#984dff' borderColor='1px solid #7a3ecc'>
            <Box p={2} bgcolor='rgba(0,0,0,.1)'>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
            <StackedBarChartIcon sx={{fontSize:'65px' , opacity:0.8}} />
            <Typography variant='h6' sx={{opacity:0.8}}>Product</Typography>
            </Box>
            <Box onClick={()=>handleOpen('Product')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
            <Typography variant='subtitle2'>View Report</Typography>
            <ArrowForwardIcon sx={{fontSize:'20px'}} />
            </Box>
            </Box>
            </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Purchase')} color='#fff' component={Card} bgcolor='#65a6ff' borderColor='1px solid #5388d1'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <BalanceIcon sx={{fontSize:'65px' , opacity:0.8}} />
             <Typography  variant='h6' sx={{opacity:0.8}}>Purchase</Typography>
             </Box>
             <Box onClick={()=>handleOpen('Purchase')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
             <Typography variant='subtitle2'>View Report</Typography>
             <ArrowForwardIcon sx={{fontSize:'20px'}} />
             </Box>
           </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
          <Box onClick={()=>handleOpen('Expense')} color='#fff' component={Card} bgcolor='#4e7d75' borderColor='1px solid #4e7d75'>
          <Box p={2} bgcolor='rgba(0,0,0,.1)'>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
          <PriceCheckIcon sx={{fontSize:'65px' , opacity:0.8}} />
              <Typography  variant='h6' sx={{opacity:0.8}}>Expense</Typography>
              </Box>
        
          <Box p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
          <Typography variant='subtitle2'>View Report</Typography>
          <ArrowForwardIcon sx={{fontSize:'20px'}} />
          </Box>
          </Box>
      </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Web Orders')} color='#fff' component={Card} bgcolor='#d67520' borderColor='1px solid #e67e22'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <ShoppingCartIcon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Web Orders</Typography>
                      </Box>
               
                  <Box onClick={()=>handleOpen('Web Orders')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>


              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Channel Sale')} color='#fff' component={Card} bgcolor='#ab6666' borderColor='1px solid #ab6666'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <StoreIcon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Channel Sales</Typography>
                      </Box>
                 
                  <Box onClick={()=>handleOpen('Channel Sale')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Whole Sale')} color='#fff' component={Card} bgcolor='#ac56a4' borderColor='1px solid #ab6666'>
                  <Box p={2} bgcolor='rgba(0, 0, 0, 0.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <Diversity2Icon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Whole Sale</Typography>
                      </Box>
                 
                  <Box onClick={()=>handleOpen('Whole Sale')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('People')} color='#ccc' component={Card} bgcolor='#2d2d2d' borderColor='1px solid #000'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <Groups2Icon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Supplier</Typography>
                      </Box>
                
                  <Box onClick={()=>handleOpen('People')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Stock')} color='#fff' component={Card} bgcolor='#db3b8a' borderColor='1px solid #db3b8a'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <PieChartIcon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Stock</Typography>
                      </Box>
                 
                  <Box p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
              <Box onClick={()=>handleOpen('Employee')} color='#fff' component={Card} bgcolor='#00bca4'>
              <Box p={2} bgcolor='rgba(0,0,0,.1)'>
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center' >
              <Diversity3Icon sx={{fontSize:'65px' , opacity:0.8}} />
              <Typography sx={{opacity:0.8}} variant='h6'>Employee Record</Typography>
              </Box>
              <Box onClick={()=>handleOpen('Employee')} p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
              <Typography variant='subtitle2'>View Report</Typography>
              <ArrowForwardIcon sx={{fontSize:'20px'}} />
              </Box>
              </Box>
          </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} sx={{cursor:'pointer'}}>
                  <Box onClick={()=>handleOpen('Profit')} color='#fff' component={Card} bgcolor='#5ec019' borderColor='1px solid #5ec019'>
                  <Box p={2} bgcolor='rgba(0,0,0,.1)'>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' p={2} alignContent='center'>
                  <SavingsIcon sx={{fontSize:'65px' , opacity:0.8}} />
                      <Typography  variant='h6' sx={{opacity:0.8}}>Profit</Typography>
                      </Box>
                 
                  <Box p={1} display='flex' flexDirection='row' justifyContent='space-between' bgcolor='rgba(0,0,0,.1)'>
                  <Typography variant='subtitle2'>View Report</Typography>
                  <ArrowForwardIcon sx={{fontSize:'20px'}} />
                  </Box>
                  </Box>
              </Grid>
          </Grid>
   </Box>
  )
}

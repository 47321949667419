import React, { useEffect, useState } from 'react'
import { Card, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText, Checkbox, InputAdornment, IconButton, Typography, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Heading from '../../Components/Heading/Heading';
import { admin_signup, get_admin, update_admin } from '../../Services/APIService';
import { appendData } from '../../Variables/ProcessVariable';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

function EditAccess() {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [adminName, setAdminName] = useState('');
    const [email, setEmail] = useState('');
    const [Existingemail, setExistingEmail] = useState('');
    const [mobileNum, setMobileNum] = useState('');
    const [roles, setRoles] = useState('');
    const [password, setPassword] = useState('');
    const [dashboardView, setDashboardView] = useState(1);
    const [addCategoryView, setAddCategoryView] = useState('');
    const [updateCategoryView, setUpdateCategoryView] = useState('');
    const [addProductView, setAddProductView] = useState('');
    const [updateProductView, setUpdateProductView] = useState('');
    const [allProductView, setAllProductView] = useState('');
    const [archiveProductView, setArchiveProductView] = useState('');
    const [purchaseListView, setPurchaseListView] = useState('');
    const [addPurchaseView, setAddPurchaseView] = useState('');
    const [vendorView, setVendorView] = useState('');
    const [webOrderView, setWebOrderView] = useState('');
    const [channelOrderView, setChannelOrderView] = useState('');
    const [returnRefundView, setReturnRefundView] = useState('');
    const [expenseView, setExpenseView] = useState('');
    const [expenseCategoryView, setExpenseCategoryView] = useState('');
    const [customerView, setCustomerView] = useState('');
    const [inquiryView, setInquiryView] = useState('');
    const [homePageView, setHomePageView] = useState('');
    const [trendingView, setTrendingView] = useState('');
    const [saleView, setSaleView] = useState('');
    const [brandsView, setBrandsView] = useState('');
    const [accessView, setAccessView] = useState('');
    const [authorizationView, setAuthorizationView] = useState('');
    const [reportView, setReportView] = useState('');
    const [InBoundView, setInBoundView ] = useState('');
    const[OutBoundView , setOutBoundView ] = useState('');
    const [wholeSaleView, setWholeSaleView] = useState('');
    const [bankStatementView, setBankStatementView] = useState('');
    const [cashFlowView, setCashFlowView] = useState('');
    const [incomeStatementView, setIncomeStatementView] =useState('');
    const [balanceSheetView, setBalanceSheetView] = useState('');
    const location = useLocation();
    const { Id } = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        const sendData = new FormData()
        sendData.append('adminId', Id)
        axios({
            method: "POST",
            url: get_admin,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setAdminName(res.data.data.adminName);
                setEmail(res.data.data.email);
                setExistingEmail(res.data.data.email);
                setMobileNum(res.data.data.mobileNum);
                setRoles(res.data.data.roles);
                setDashboardView(res.data.data.dashboardView);
                setAddCategoryView(res.data.data.addCategoryView);
                setUpdateCategoryView(res.data.data.updateCategoryView);
                setAddProductView(res.data.data.addProductView);
                setUpdateProductView(res.data.data.updateProductView);
                setAllProductView(res.data.data.allProductView);
                setArchiveProductView(res.data.data.archiveProductView);
                setPurchaseListView(res.data.data.purchaseListView);
                setAddPurchaseView(res.data.data.addPurchaseView);
                setVendorView(res.data.data.vendorView);
                setWebOrderView(res.data.data.webOrderView);
                setChannelOrderView(res.data.data.channelOrderView);
                setReturnRefundView(res.data.data.returnRefundView);
                setExpenseView(res.data.data.expenseView);
                setExpenseCategoryView(res.data.data.expenseCategoryView);
                setCustomerView(res.data.data.customerView);
                setInquiryView(res.data.data.inquiryView);
                setHomePageView(res.data.data.homePageView);
                setTrendingView(res.data.data.trendingView);
                setSaleView(res.data.data.saleView);
                setBrandsView(res.data.data.brandsView);
                setAccessView(res.data.data.accessView);
                setAuthorizationView(res.data.data.authorizationView);
                setReportView(res.data.data.reportView);
                setInBoundView(res.data.data.reportView);
                setOutBoundView(res.data.data.reportView);
                setWholeSaleView(res.data.data.wholeSaleView);
                setBankStatementView(res.data.data.bankStatementView);
                setCashFlowView(res.data.data.cashFlowView);
                setIncomeStatementView(res.data.data.incomeStatementView);
                setBalanceSheetView(res.data.data.balanceSheetView);

            }
        }).catch(err => {
            console.log(err)
        });
    },[]);


    const onSubmit = () => {
        const obj = {
            adminId: Id,
            adminName: adminName,
            mobileNum: mobileNum,
            roles: roles,
            password: password,
            dashboardView: dashboardView,
            addCategoryView: addCategoryView,
            updateCategoryView: updateCategoryView,
            addProductView: addProductView,
            updateProductView: updateProductView,
            allProductView: allProductView,
            archiveProductView: archiveProductView,
            purchaseListView: purchaseListView,
            addPurchaseView: addPurchaseView,
            vendorView: vendorView,
            webOrderView: webOrderView,
            channelOrderView: channelOrderView,
            returnRefundView: returnRefundView,
            expenseView: expenseView,
            expenseCategoryView: expenseCategoryView,
            customerView: customerView,
            inquiryView: inquiryView,
            homePageView: homePageView,
            trendingView: trendingView,
            saleView: saleView,
            brandsView: brandsView,
            accessView: accessView,
            authorizationView: authorizationView,
            reportView: reportView,
            inboundView:InBoundView,
            outboundView:OutBoundView,
            wholeSaleView:wholeSaleView, 
            bankStatementView:bankStatementView,
             cashFlowView:cashFlowView, 
             incomeStatementView:incomeStatementView,
              balanceSheetView:balanceSheetView
        }
        if (Existingemail !== email) {
            obj["email"] = email;
        }
        const sendData = appendData(obj);
            axios({
                method: 'POST',
                url: update_admin,
                data: sendData,
            }).then(res => {
                console.log(res)
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(true)
                    setColor(true)
                    navigate(-1);
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
    }



    return (
        <Box sx={{ py: 2 }}>
            <Box p={3}>
                <Heading title={'Create Access'} />
            </Box>
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box p={1}>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item textAlign={'center'} xs={12} lg={8}>
                        <Box component={Card} sx={{ p: 2, boxShadow: 3, borderRadius: 5 }}>
                            <Form >
                                <Grid container justifyContent='start' spacing={1}>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Employee Name"
                                                variant="filled"
                                                autoComplete='off'
                                                value={adminName}
                                                onChange={(e) => setAdminName(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                variant="filled"
                                                autoComplete='off'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                autoComplete='off'
                                                value={password}
                                                type={showPassword ? 'password' : 'text'}
                                                onChange={(e) => setPassword(e.target.value)}
                                                label="Password"
                                                InputProps={{
                                                    endAdornment:
                                                        (<InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Contact Number"
                                                variant="filled"
                                                autoComplete='off'
                                                value={mobileNum}
                                                onChange={(e) => setMobileNum(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <FormControl fullWidth>
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    value={roles}
                                                    sx={{ textAlign: 'start' }}
                                                    label="Role"
                                                    onChange={(e) => setRoles(e.target.value)}
                                                >
                                                    <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
                                                    <MenuItem value={"Admin"}>Admin</MenuItem>
                                                    <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                                    <MenuItem value={"Accountant"}>Accountant</MenuItem>
                                                    <MenuItem value={"Senior Executive"}>Senior Executive</MenuItem>
                                                    <MenuItem value={"Executive"}>Executive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Dashboard</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Dashboard
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={dashboardView === 'true'} onChange={() => setDashboardView((prevValue) => !prevValue )} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Category</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create New Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={addCategoryView === 'true'} onChange={() => setAddCategoryView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Update Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={updateCategoryView === 'true'} onChange={(e) => setUpdateCategoryView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Products</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create New Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={addProductView === 'true'} onChange={(e) => setAddProductView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Update Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={updateProductView === 'true'} onChange={(e) => setUpdateProductView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                All Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={allProductView === 'true'} onChange={(e) => setAllProductView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Archive Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={archiveProductView === 'true'} onChange={(e) => setArchiveProductView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Purchase</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Purchase List
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={purchaseListView === 'true'} onChange={(e) => setPurchaseListView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create New Purchase
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={addPurchaseView === 'true'} onChange={(e) => setAddPurchaseView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Vendors
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={vendorView === 'true'} onChange={(e) => setVendorView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Sale Order</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Web Order
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={webOrderView === 'true'} onChange={(e) => setWebOrderView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Channel Sale
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={channelOrderView === 'true'} onChange={(e) => setChannelOrderView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                          
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Whole Sale
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={wholeSaleView === 'true'} onChange={(e) => setWholeSaleView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>

                                                          
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Return and Refunds
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={returnRefundView === 'true'} onChange={(e) => setReturnRefundView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Revenue</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Account
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox  checked={bankStatementView === 'true'} onChange={(e) => setBankStatementView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Income Statement
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox  checked={incomeStatementView === 'true'} onChange={(e) => setIncomeStatementView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Cash Flow
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox  checked={cashFlowView === 'true'} onChange={(e) => setCashFlowView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Balance Sheet
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox  checked={balanceSheetView === 'true'} onChange={(e) => setBalanceSheetView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Expense</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Expense
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={expenseView === 'true'} onChange={(e) => setExpenseView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Expense Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={expenseCategoryView === 'true'} onChange={(e) => setExpenseCategoryView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>People</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Customers
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={customerView === 'true'} onChange={(e) => setCustomerView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Inquiry
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={inquiryView === 'true'} onChange={(e) => setInquiryView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Website UI</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Homepage
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={homePageView === 'true'} onChange={(e) => setHomePageView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Trending
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={trendingView === 'true'} onChange={(e) => setTrendingView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Sale
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={saleView === 'true'} onChange={(e) => setSaleView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Brands -  Bulk
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={brandsView === 'true'} onChange={(e) => setBrandsView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Access
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={accessView === 'true'} onChange={(e) => setAccessView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Authorization List
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={authorizationView === 'true'} onChange={(e) => setAuthorizationView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Report</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Report
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={reportView === 'true'} onChange={(e) => setReportView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>InBound & OutBound</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            InBound
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={InBoundView === 'true'} onChange={(e) => setInBoundView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            OutBound
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={OutBoundView === 'true'} onChange={(e) =>  setOutBoundView((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2, textAlign: 'center' }}>
                                    <Button onClick={onSubmit} variant="contained" endIcon={<SendIcon />}>Submit</Button>
                                </Box>
                            </Form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default EditAccess
import React from 'react'
import { Typography } from '@mui/material';

function Heading({ title }) {
    return (
        <Typography
            variant="h3"
            sx={{ textAlign: 'left', fontWeight: 500, fontSize: 24, textTransform: 'uppercase', color: '#367C75' }}
        >
            {title}
        </Typography>
    )
}

export default Heading
import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    IconButton,
    Box, Container, Stack, Typography, Button
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { add_brand_images, delete_brand_image, delete_home_page_image, get_brand_image, get_home_image1, update_brand_image, update_home_page_image } from "../../../Services/APIService";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import Heading from "../../../Components/Heading/Heading";
import SnackBar from "../../../Components/AdminDashBoardComps/Snackbar";

export default function BulkBrandLayout({BrandName , Layout, productId }) {

    const [editMode, setEditMode] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageId, setImageId] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

    const [Data, setData] = useState([]);
    const [editedData, setEditedData] = useState({
        Discretion: "",
        productName: "",
    });


    const [newData, setNewData] = useState({
        Discretion: "",
        productName: "",
    });

      
    const [newImage, setNewImage] = useState(null);

    const handleEditClick = (id) => {
        setImageId(id);
        setEditMode(true);
        getParticularData(id);
    };

    const handleImageChange = (event) => {
        const newImage = event.target.files[0];
        setSelectedImage(newImage);
    };


    const getParticularData = (id) => {
        if(id !== "" && id !== undefined){
          const sendData = new FormData()
          sendData.append('brandId', id);
          axios({
            method: 'POST',
            url: get_brand_image,
            data: sendData,
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setEditedData({
                Discretion: res.data.data.descriptions,
                productName: res.data.data.productName,
              })
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      }

    const Cancel = () => {
        setSelectedImage(null);
        setEditMode(false);
    }


    const handleClick = () => {
        const serverData = new FormData()
        serverData.append("brandName", BrandName);
        serverData.append("descriptions", editedData.Discretion);
        serverData.append("productName", editedData.productName);
        serverData.append('brandId', imageId);
        serverData.append("images", selectedImage);
        axios({
          method: "POST",
          url: update_brand_image,
          data: serverData,
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setSelectedImage(null);
            setEditMode(false);
            setImageId('');
            getAllData();
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          console.log(err)
        });
      }

    const getAllData = () => {
        const sendData = new FormData()
        sendData.append('brandName', BrandName);
        axios({
            method: 'POST',
            url: get_brand_image,
            data:sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setData([]);
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setData(res.data.data);
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }

    useEffect(()=>{
        getAllData();
    },[])

    const result = Array.isArray(Data) ? Data : [Data];


    const AddNew = () =>{
        if(newData.Discretion !== '' && newData.productName !== ''  && newImage !== null) {
        const serverData = new FormData()
        serverData.append("brandName", BrandName) 
        serverData.append("descriptions", newData.Discretion) 
        serverData.append("productName", newData.productName)
        serverData.append("images", newImage)
        axios({
          method: "POST",
          url: add_brand_images,
          data: serverData,
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setNewImage(null);
            document.getElementById('newsliderImage').value ='';
            setNewData({
                Discretion: "",
                productName: "",
            });
            getAllData();
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          console.log(err)
        });
      }
        else{
          setOpen(true)
            setStatus(false)
            setColor(false)
          setMessage('Fill all the Fields');
        }
      }


      const DeleteData = (BrandId) => {
        if (BrandId !== "") {
          const sendData = new FormData()
          sendData.append('brandId', BrandId);
          axios({
            method: 'POST',
            url: delete_brand_image,
            data: sendData,
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              getAllData();
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      }

    return (
        <Box>
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
              <Box textAlign='left' mb={4}>
      <Typography variant='body1' color='#ff5d9e'>Required Dimension : 385* 385 </Typography>
    </Box>
            <Container>
                <Grid container spacing={3}>
                    {result && result.map((image) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={image.brandId}>
                            {editMode && imageId === image.brandId ? (
                                <Stack spacing={2}>
                                    <TextField
                                        label="Title"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        value={editedData.productName}
                                        onChange={(e) =>
                                            setEditedData({ ...editedData, productName: e.target.value })
                                        }
                                    />
                                    <TextField
                                        label="Content"
                                        fullWidth
                                        multiline
                                        size="small"
                                        rows={4}
                                        variant="outlined"
                                        value={editedData.Discretion}
                                        onChange={(e) =>
                                            setEditedData({ ...editedData, Discretion: e.target.value })
                                        }
                                    />
                                    <TextField
                                        type="file"
                                        size="small"
                                        onChange={handleImageChange}
                                        inputProps={{ accept: "image/*" }}
                                        sx={{ marginTop: 2 }}
                                    />
                                    <Box display='flex' flexDirection='row'>
                                    <Button onClick={handleClick}>Update</Button>
                                    <Button onClick={Cancel} color='error'>Cancel</Button>
                                    </Box>
                                </Stack>
                            ) : (
                                <Stack>
                                    <Box display='flex' flexDirection='row' alignContent='center'>
                                        <img
                                           src={image.images}
                                            style={{
                                                width: 200,
                                                height: 200,
                                                objectFit: "contain",
                                            }}
                                            alt=""
                                        />
                                        <Box display='flex' flexDirection='column' p={1}>
                                        <IconButton
                                        onClick={() => handleEditClick(image.brandId)}
                                        color="primary"
                                        sx={{ marginTop: 1 }}
                                      >
                                        <EditIcon sx={{ verticalAlign: 'middle' }} />
                                      </IconButton>
              
                                      <IconButton
                                        onClick={() => DeleteData(image.brandId)}
                                        color="error"
                                        sx={{ marginTop: 1 }}
                                      >
                                        <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                                      </IconButton>

                                        </Box>
                                    </Box>
                                    <Box textAlign='left'>
                                            <Typography variant='subtitle2'><strong>Title: </strong> {image.productName}</Typography>
                                            <Typography variant='subtitle2'><strong>Content: </strong>{image.descriptions}</Typography>
                                    </Box>
                                </Stack>
                            )}
                        </Grid>
                    ))}
                </Grid>


                <Box  mt={5} mb={2}>
                <Heading title={'Add New Item'} />
              </Box>
        
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='start'>
                <Stack spacing={2}>
                <TextField
                label="Title"
                fullWidth
                size="small"
                variant="outlined"
                value={newData.productName}
                onChange={(e) =>
                    setNewData({ ...newData, productName: e.target.value })
                }
            />
            <TextField
                label="Content"
                fullWidth
                size="small"
                variant="outlined"
                value={newData.Discretion}
                onChange={(e) =>
                    setNewData({ ...newData, Discretion: e.target.value })
                }
            />
            <TextField
                type="file"
                id='newsliderImage'
                size="small"
                onChange={(e)=>setNewImage(e.target.files[0])}
                inputProps={{ accept: "image/*" }}
                sx={{ marginTop: 2 }}
            />
                <Box display='flex' flexDirection='row'> 
                  <Button 
                  variant="contained"
                  color='warning' onClick={AddNew}>Generate</Button>
                </Box>
              </Stack>
                </Grid>
                </Grid>


            </Container>
        </Box>
    )
}

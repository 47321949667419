import React, { useState } from 'react'
import { Card, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, Checkbox, InputAdornment, IconButton, Typography, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Heading from '../../Components/Heading/Heading';
import { admin_signup } from '../../Services/APIService';
import { appendData } from '../../Variables/ProcessVariable';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

function Access() {
    const { formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [adminName, setAdminName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNum, setMobileNum] = useState('');
    const [roles, setRoles] = useState('');
    const [password, setPassword] = useState('');
    const [dashboardView, setDashboardView] = useState(1);
    const [addCategoryView, setAddCategoryView] = useState('');
    const [updateCategoryView, setUpdateCategoryView] = useState('');
    const [addProductView, setAddProductView] = useState('');
    const [updateProductView, setUpdateProductView] = useState('');
    const [allProductView, setAllProductView] = useState('');
    const [archiveProductView, setArchiveProductView] = useState('');
    const [purchaseListView, setPurchaseListView] = useState('');
    const [addPurchaseView, setAddPurchaseView] = useState('');
    const [vendorView, setVendorView] = useState('');
    const [webOrderView, setWebOrderView] = useState('');
    const [channelOrderView, setChannelOrderView] = useState('');
    const [returnRefundView, setReturnRefundView] = useState('');
    const [expenseView, setExpenseView] = useState('');
    const [expenseCategoryView, setExpenseCategoryView] = useState('');
    const [customerView, setCustomerView] = useState('');
    const [inquiryView, setInquiryView] = useState('');
    const [homePageView, setHomePageView] = useState('');
    const [trendingView, setTrendingView] = useState('');
    const [saleView, setSaleView] = useState('');
    const [brandsView, setBrandsView] = useState('');
    const [accessView, setAccessView] = useState('');
    const [authorizationView, setAuthorizationView] = useState('');
    const [reportView, setReportView] = useState('');
    const [InBoundView, setInBoundView ] = useState('');
    const[OutBoundView , setOutBoundView ] = useState('');

    const [wholeSaleView, setWholeSaleView] = useState('');
    const [bankStatementView, setBankStatementView] = useState('');
    const [cashFlowView, setCashFlowView] = useState('');
    const [incomeStatementView, setIncomeStatementView] =useState('');
    const [balanceSheetView, setBalanceSheetView] = useState('');
  

    const onSubmit = () =>{
        const obj = {
            adminName: adminName,
            email: email,
            mobileNum: mobileNum,
            roles: roles,
            password: password,
            dashboardView: dashboardView,
            addCategoryView: addCategoryView,
            updateCategoryView: updateCategoryView,
            addProductView: addProductView,
            updateProductView: updateProductView,
            allProductView: allProductView,
            archiveProductView: archiveProductView,
            purchaseListView: purchaseListView,
            addPurchaseView: addPurchaseView,
            vendorView: vendorView,
            webOrderView: webOrderView,
            channelOrderView: channelOrderView,
            returnRefundView: returnRefundView,
            expenseView: expenseView,
            expenseCategoryView: expenseCategoryView,
            customerView: customerView,
            inquiryView: inquiryView,
            homePageView: homePageView,
            trendingView: trendingView,
            saleView: saleView,
            brandsView: brandsView,
            accessView: accessView,
            authorizationView: authorizationView,
            reportView: reportView,
            inboundView:InBoundView,
            outboundView:OutBoundView,
            wholeSaleView:wholeSaleView, 
            bankStatementView:bankStatementView,
             cashFlowView:cashFlowView, 
             incomeStatementView:incomeStatementView,
              balanceSheetView:balanceSheetView
        }
        const sendData = appendData(obj);
            axios({
                method: 'POST',
                url: admin_signup,
                data: sendData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
    }

    

    return (
        <Box sx={{ py: 2 }}>
            <Box p={3}>
                <Heading title={'Create Access'} />
            </Box>
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box p={1}>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item textAlign={'center'} xs={12} lg={8}>
                        <Box component={Card} sx={{ p: 2, boxShadow: 3, borderRadius: 5 }}>
                            <Form >
                                <Grid container justifyContent='start' spacing={1}>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Employee Name"
                                                variant="filled"
                                                autoComplete='off'
                                                onChange={(e)=>setAdminName(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                variant="filled"
                                                autoComplete='off'
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                autoComplete='off'
                                                type={showPassword ? 'password' : 'text'}
                                               onChange={(e) => setPassword(e.target.value)}
                                                label="Password"
                                                InputProps={{
                                                    endAdornment:
                                                        (<InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>),
                                                }}                                          
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Contact Number"
                                                variant="filled"
                                                autoComplete='off'
                                            onChange={(e) => setMobileNum(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <FormControl fullWidth error={errors.roles ? true : false}>
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    defaultValue=""
                                                    sx={{ textAlign: 'start' }}
                                                    label="Role"
                                                   onChange={(e) => setRoles(e.target.value)}
                                                >
                                                <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
                                                <MenuItem value={"Admin"}>Admin</MenuItem>
                                                <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                                <MenuItem value={"Accountant"}>Accountant</MenuItem>
                                                <MenuItem value={"Senior Executive"}>Senior Executive</MenuItem>
                                                <MenuItem value={"Executive"}>Executive</MenuItem>
                                                </Select>                                            
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Dashboard</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Dashboard
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setDashboardView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Category</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create New Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAddCategoryView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Update Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setUpdateCategoryView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Products</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create New Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAddProductView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Update Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setUpdateProductView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                All Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAllProductView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Archive Products
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setArchiveProductView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Purchase</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>
                                                        
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Purchase List
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setPurchaseListView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Create New Purchase
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAddPurchaseView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Vendors
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setVendorView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Sale Order</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Web Order
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setWebOrderView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Channel Sale
                                                            </TableCell>

                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setWholeSaleView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Whole Sale
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setChannelOrderView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Return and Refunds
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setReturnRefundView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Revenue</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Account
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setBankStatementView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Income Statement
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setIncomeStatementView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Cash Flow
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setCashFlowView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Balance Sheet
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setBalanceSheetView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Expense</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Expense
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setExpenseView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Expense Category
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setExpenseCategoryView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>                                             
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>People</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Customers
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setCustomerView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Inquiry
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setInquiryView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Website UI</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Homepage
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setHomePageView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Trending
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setTrendingView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Sale
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setSaleView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Brands -  Bulk
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setBrandsView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Access
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAccessView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Authorization List
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAuthorizationView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Report</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Report
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setReportView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>InBound & OutBound</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            InBound
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setInBoundView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            OutBound
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) =>  setOutBoundView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2, textAlign: 'center' }}>
                                    <Button onClick={onSubmit} variant="contained" endIcon={<SendIcon />}>Submit</Button>
                                </Box>
                            </Form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Access
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Button, Container, Grid, Typography } from '@mui/material';
import { getmainproducts, get_allproducts, BaseImageAPI, get_admin } from '../../Services/APIService';
import { accessTable, orderTable, productsTable } from '../../Variables/Variables';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useNavigate } from 'react-router-dom';

export default function AuthPersonList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [adminId, setAdminId] = useState('');  
  const navigate = useNavigate();

  useEffect(() => {
    const sendData = new FormData();
    sendData.append('adminId', adminId)
    axios({
      method: "POST",
      url: get_admin,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setOpen(true)
        setMessage(res.data.message)
        setProducts(res.data.data);
        setStatus(true)
        setColor(true)
      }
    }).catch(err => {
      console.log(err)
    });
  }, [])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const openEditPage = (id) => {
    navigate('/dashbrdlayout/editaccess', { state: { Id: id } })
  }


  return (
    <Box p={2}>
      <Box p={3}>
        <Heading title={'Authorized Person List'} />
      </Box>
      <Container>
        <Box mt={2} py={4}>
          <Grid container>
            <TableContainer sx={{ border: '1px solid silver' }} >

              <Table>
                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                  <TableRow sx={{ borderBottom: '1px solid silver' }}>
                    {
                      accessTable.map((i, index) => {
                        return (
                          <TableCell colSpan={i.title === 'Color Qty' ? 2 : 1} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Box sx={{ my: 'auto' }}>
                                {i.title}
                              </Box>
                              <Box>{
                                i.filter &&
                                <Filter search={search} setSearch={setSearch} setPage={setPage} />
                              }
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>


                <TableBody>

                  {
                    products.filter((data) => FilterData(data, search, {
                      searchFeildOne: data.productId,
                      searchFeildTwo: data.productName,
                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                      return (
                        <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.adminId}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.adminName}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.roles}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.email}</TableCell>
                          <TableCell>
                          <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                              <Button onClick={() => openEditPage(i.adminId)} 
                              variant="contained"
                              sx={{ height: 30, width: 100, fontWeight: 600, opacity: 0.9, fontSize: 12 }}
                              color='warning'>
                              <DriveFileRenameOutlineIcon
                                sx={{ verticalAlign: 'middle', mr: 1 }}
                              />
                              UPDATE
                            </Button>
                          </Box>
                        </TableCell>
                        </TableRow>
                      )
                    })
                  }

                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={products.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Heading from "../../Components/Heading/Heading";
import axios from "axios";
import {
  Container,
  Grid,
  Card,
  Typography,
  Button,
  IconButton,
  Dialog,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import PaidIcon from "@mui/icons-material/Paid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FastRewindOutlinedIcon from "@mui/icons-material/FastRewindOutlined";
import { get_purchase, purchase_bill } from "../../Services/APIService";
import SnackBar from "../../Components/AdminDashBoardComps/Snackbar";
import PrintIcon from "@mui/icons-material/Print";

export default function PurchaseDetails() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [purchaseList, setPurchaseList] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const { id } = location.state;
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageDialog = (data) => {
    setOpenImageDialog(!openImageDialog);
    setSelectedImage(data);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const sendData = new FormData();
    sendData.append("purchaseId", id);
    axios({
      method: "POST",
      url: get_purchase,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setPurchaseList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const [date, time] = purchaseList.purchaseDate
    ? purchaseList.purchaseDate.split(" ")
    : [null, null];

  const totalQuantity = purchaseList?.purchaseProduct?.reduce(
    (total, product) => {
      return total + product.purchaseQty;
    },
    0
  );

  const handlegenerateInvoice = () => {
    const sendData = new FormData();
    sendData.append("purchaseId", purchaseList.purchaseId);

    axios({
      method: "POST",
      url: purchase_bill,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);

          // Open the URL in a new tab
          const newTab = window.open(res.data.data, "_blank");

          // Check if the new tab has been successfully opened
          if (newTab !== null) {
            // Add a timeout to ensure the URL has loaded before printing
            setTimeout(() => {
              newTab.print(); // Print the contents of the new tab
            }, 1000); // Adjust the timeout delay as needed
          } else {
            alert(
              "Popup blocker prevented opening the new tab. Please allow popups."
            );
          }
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  return (
    <Box p={2}>
      <Box p={3}>
        <Heading title={"Purchase Details"} />
      </Box>

      <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
        <img src={selectedImage} alt="Fuel" />
      </Dialog>

      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />
      <Box component={Card} sx={{ boxShadow: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box component={Card} pb={2} border="1px solid silver">
              <Box bgcolor="#00bca4" py={4}>
                <ContactEmergencyIcon sx={{ color: "#FFFFFF" }} />
                <Typography fontWeight={600} color="#FFFFFF" variant="h6">
                  Supplier Name
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Name:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {purchaseList.supplierName}
                  </Typography>
                </Box>

                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Email:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {purchaseList.email}
                  </Typography>
                </Box>

                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Address:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {purchaseList.stateName}, {purchaseList.country}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box component={Card} pb={2} border="1px solid silver">
              <Box bgcolor="#65a6ff" sx={{ color: "#FFFFFF" }} py={4}>
                <ReceiptIcon />
                <Typography color="#FFFFFF" fontWeight={600} variant="h6">
                  Bill Info
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Ref No:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {purchaseList.billNo}
                  </Typography>
                </Box>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Date:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {date}
                  </Typography>
                </Box>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Time:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {time}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box component={Card} pb={2} border="1px solid silver">
              <Box bgcolor="#984dff" py={4}>
                <PaidIcon sx={{ color: "#FFFFFF" }} />
                <Typography color="#FFFFFF" fontWeight={600} variant="h6">
                  Payment Info
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Mode:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    {purchaseList.paymentMethod}
                  </Typography>
                </Box>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Paid:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    ${purchaseList.paidAmount}
                  </Typography>
                </Box>
                <Box
                  p={2}
                  border="1px solid silver"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap={1}
                >
                  <Typography
                    color="#616e80"
                    fontWeight={600}
                    variant="subtitle2"
                  >
                    Due:
                  </Typography>
                  <Typography fontWeight={600} variant="subtitle2">
                    ${purchaseList.dueAmount}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Container>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} mt={4} mb={1}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="success"
              textColor="inherit"
              aria-label="full width tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                label="Purchase Items"
                sx={{
                  bgcolor: value === 0 && "#00bca4",
                  border: value === 0 && "1px solid silver",
                  borderRadius: value === 0 && "4px",
                  textTransform: "none",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Color & Qty"
                sx={{
                  bgcolor: value === 1 && "#00bca4",
                  border: value === 1 && "1px solid silver",
                  borderRadius: value === 1 && 1,
                  textTransform: "none",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Payment History"
                sx={{
                  bgcolor: value === 2 && "#00bca4",
                  border: value === 2 && "1px solid silver",
                  borderRadius: value === 2 && 2,
                  textTransform: "none",
                }}
                {...a11yProps(1)}
              />
              <Tab
                label="Payment Due"
                sx={{
                  bgcolor: value === 3 && "#00bca4",
                  border: value === 3 && "1px solid silver",
                  borderRadius: value === 3 && 3,
                  textTransform: "none",
                }}
                {...a11yProps(2)}
              />
              <Tab
                label="Attachment"
                sx={{
                  bgcolor: value === 4 && "#00bca4",
                  border: value === 4 && "1px solid silver",
                  borderRadius: value === 4 && 4,
                  textTransform: "none",
                }}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>

          {/* Purchase items */}

          <TabPanel value={value} index={0}>
            <Box mb={2}>
              <TableContainer>
                <Table
                  bgcolor="#F9F9F8"
                  sx={{ borderLeft: "1px solid silver" }}
                >
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Product
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell align="center">Sub Total($)</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {purchaseList?.purchaseProduct?.map((i, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid silver" }}
                          >
                            {i.categoryName}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid silver" }}
                          >
                            {i.productName}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid silver" }}
                          >
                            {i.purchaseQty}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid silver" }}
                          >
                            {i.productUnitCost}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid silver" }}
                          >
                            {parseFloat(i.subTotal).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell sx={{ borderLeft: "1px solid silver" }} />
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{
                          borderRight: "1px solid silver",
                          fontWeight: 600,
                        }}
                      >
                        Total Quantity :
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {totalQuantity}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ borderLeft: "1px solid silver" }} />
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{
                          borderRight: "1px solid silver",
                          fontWeight: 600,
                        }}
                      >
                        Discount($) :
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.discountAmount}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Net Total($) :
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.netTotal}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          {/* Color Qty */}

          <TabPanel value={value} index={1}>
            <Box mb={2}>
              <TableContainer>
                <Table
                  bgcolor="#F9F9F8"
                  sx={{ borderLeft: "1px solid silver" }}
                >
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Color
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseList?.colorQty?.map((i) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            {i.productName}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            {i.color}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            {i.qty}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          {/* Payment  */}
          <TabPanel value={value} index={2}>
            <Box mb={2}>
              <TableContainer>
                <Table
                  bgcolor="#F9F9F8"
                  sx={{ borderLeft: "1px solid silver" }}
                >
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Method
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Amount Paid($)
                      </TableCell>
                      <TableCell align="center">PRINT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          borderLeft: "1px solid silver",
                        }}
                      >
                        {purchaseList.purchaseDate}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.paymentMethod}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.paidAmount}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        <Button
                          onClick={handlegenerateInvoice}
                          variant="outlined"
                          color="warning"
                          sx={{ textTransform: "none", height: 25 }}
                        >
                          <PrintIcon
                            sx={{ verticalAlign: "middle", mr: 1 }}
                            fontSize="small"
                          />{" "}
                          Print
                        </Button>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Customer Brokerage
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.customerBrokerage}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Customs Duty:
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.customsDuty}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Freight Charges:
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.freightCharges}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Import Tax:
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.importTax}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Invoice VAT
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.invoiceVAT}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Net Total
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.netTotal}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Total Amount To Be Paid :
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.totalAmountToPaid}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Amount Paid :
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.paidAmount}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Due
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.dueAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          {/* Due */}
          <TabPanel value={value} index={3}>
            <Box mb={2}>
              <TableContainer>
                <Table
                  bgcolor="#F9F9F8"
                  sx={{ borderLeft: "1px solid silver" }}
                >
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Method
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Amount Paid ($)
                      </TableCell>
                      <TableCell align="center">DUE ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          borderLeft: "1px solid silver",
                        }}
                      >
                        {purchaseList.purchaseDate}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.paymentMethod}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.paidAmount}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.dueAmount}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      />
                      <TableCell
                        align="right"
                        colSpan={2}
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                          fontWeight: 600,
                        }}
                      >
                        Total Due:
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid silver",
                          bgcolor: "#F8FCD4",
                        }}
                      >
                        {purchaseList.dueAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          {/* Attachments */}
          <TabPanel value={value} index={4}>
            <Box mb={2}>
              <TableContainer>
                <Table
                  bgcolor="#F9F9F8"
                  sx={{ borderLeft: "1px solid silver" }}
                >
                  <TableHead sx={{ whiteSpace: "nowrap", bgcolor: "#616e80" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Attachment 1
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Attachment 2
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Attachment 3
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Attachment 4
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Attachment 5
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        Comment
                      </TableCell>
                      <TableCell align="center">Added By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {purchaseList &&
                      purchaseList?.purchaseImage?.length !== 0 ? (
                        <>
                          {purchaseList?.purchaseImage?.map((i) => {
                            return (
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid silver",
                                  borderLeft: "1px solid silver",
                                }}
                              >
                                {
                                  <img
                                    onClick={() =>
                                      handleImageDialog(i.purchaseImage)
                                    }
                                    style={{ width: 100, height: 100 }}
                                    alt="mainImage"
                                    src={i.purchaseImage}
                                  />
                                }
                              </TableCell>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            No Attachment
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            No Attachment
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            No Attachment
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            No Attachment
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid silver",
                              borderLeft: "1px solid silver",
                            }}
                          >
                            No Attachment
                          </TableCell>
                        </>
                      )}

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.comments}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid silver" }}
                      >
                        {purchaseList.commentsUser}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
        </Container>

        <Box p={1} display="flex" justifyContent="end">
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              width: 100,
              height: 40,
              border: "3px solid black",
              borderRadius: 1,
            }}
          >
            <Button
              sx={{ color: "#000000", fontWeight: 600, textTransform: "none" }}
            >
              <FastRewindOutlinedIcon sx={{ verticalAlign: "middle" }} /> Back
            </Button>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { delete_expense_category, delete_expense_sub_category } from '../../Services/APIService';
import { Typography , Box} from '@mui/material';
import axios from 'axios';

export default function ExpenseCategoryDelete({ 
getAllExpense , setMessage, setOpen, setStatus, setColor,setSelectedDelete,openDialog,
setOpenDialog, setDialogDataName, dialogDataName, setDialogDataId, dialogDataId, SelectedDelete, 
}) {
   
        const handleClose = () => {
        getAllExpense();
        setOpenDialog(false)
        setDialogDataName('')
        setDialogDataId('')
        setSelectedDelete('')
        }

        

        const handleDeleteExpenseCategory = () => {
        if (dialogDataId !== '' && dialogDataId !== null) {
        if(SelectedDelete === 'CATEGORY'){        
        const sendData = new FormData();
        sendData.append('expenseCategoryId', dialogDataId);
        axios({
        method: "POST",
        url: delete_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        }
        }).catch(err => {
        console.log(err)
        });
        }
        else{
        const sendData = new FormData();
        sendData.append('expenseSubCategoryId', dialogDataId);
        axios({
        method: "POST",
        url: delete_expense_sub_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        }
        }).catch(err => {
        console.log(err)
        });
        }
        }
        }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`ARE YOU SURE THAT YOU WANNA DELETE THIS ${SelectedDelete} PERMANENTLY?`}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant='subtitle1' fontWeight={600} color='#616e80'>{SelectedDelete} : {dialogDataName}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='primary'>CLOSE</Button>
                    <Button onClick={handleDeleteExpenseCategory} variant='outlined' autoFocus color='error'>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

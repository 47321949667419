import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { get_all_contractor } from "../../Services/APIService";


export default function ContractorInquiry() {

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

    useEffect(() => {
        axios({
            method: 'GET',
            url: get_all_contractor,
        }).then(res => {
            if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setData(res.data.data)
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }, [])

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell>Contact Person</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Website URL</TableCell>
                            <TableCell>Company Type</TableCell>
                            <TableCell>Past Experience</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Project Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.contractorCategory}</TableCell>
                                <TableCell>{item.contractorCompany}</TableCell>
                                <TableCell>{item.contractorContactPerson}</TableCell>
                                <TableCell>{item.contractorAddress}</TableCell>
                                <TableCell>{item.contractorMail}</TableCell>
                                <TableCell>{item.contractorMobile}</TableCell>
                                <TableCell>{item.websiteURL}</TableCell>
                                <TableCell>{item.companyType}</TableCell>
                                <TableCell>{item.pastExperience}</TableCell>
                                <TableCell>{item.reference}</TableCell>
                                <TableCell>{item.projectType}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

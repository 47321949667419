import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { add_expense, get_expense, get_expense_category, get_expense_sub_category, update_expense } from '../../Services/APIService';


export default function ExpensesUpdateDialog({ setExpenseListId, ExpenseListId, getAllExpense, setMessage, setOpen, setStatus, setColor, openExpenseListDialog, setOpenExpenseListDialog }) {


    const [expenseCategoryId, setexpenseCategoryId] = useState(null);
    const [expenseCategoryName, setexpenseCategoryName] = useState(null);
    const [expenseSubCategoryId, setexpenseSubCategoryId] = useState(null);
    const [expenseSubCategoryName, setexpenseSubCategoryName] = useState(null);
    const [expenseAmount, setexpenseAmount] = useState('');
    const [expenseDetails, setexpenseDetails] = useState('');
    const [expenseDate, setexpenseDate] = useState('');
    const[recurring, setRecurring] = useState(null)
    const[recurringType, setRecurringType] = useState(null)
    const [recurringDate, setRecurringDate] = useState('')
    const [ExpenseCategory, setExpenseCategory] = useState([]);
    const [ExpenseSubCategory, setExpenseSubCategory] = useState([]);
    const AdminId = localStorage.getItem('AdminUserId'); 
    const [input, setInput] = useState(false);
    const [ManualSub, setManualSub] =useState(null);
    const [companyName , setCompanyName] = useState('')

    const handleClose = () => {
        setOpenExpenseListDialog(false);
        setExpenseListId('');
        setexpenseCategoryId('');
        setexpenseCategoryName('');
        setexpenseSubCategoryName(null)
        setexpenseAmount('');
        setexpenseDetails('');
        setexpenseDate('');
        setRecurring(null);
        getAllExpense();
    }

    const getAllExpenseCategory = () => {
        const sendData = new FormData();
        sendData.append('expenseCategoryId', '');
        axios({
            method: "POST",
            url: get_expense_category,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setExpenseCategory(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getAllExpenseCategory();
    }, []);


    const getAllExpenseSubCategory = () => {
        if(expenseCategoryId !== null && expenseCategoryId !== '' && expenseCategoryId !== undefined){
        const sendData = new FormData();
        sendData.append('expenseCategoryId', expenseCategoryId);
        axios({
            method: "POST",
            url: get_expense_sub_category,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setExpenseSubCategory(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }
    }

    useEffect(() => {
        getAllExpenseSubCategory();
    }, [expenseCategoryId]);


    const Categoryoptions = ExpenseCategory.map((i) => {
        const obj = {
            'label': i.expenseCategory,
            'id': i.expenseCategoryId
        }
        return obj;
    });

    const SubCategoryoptions = ExpenseSubCategory.map((i) => {
        const obj = {
            'label': i.expenseSubCategory,
            'id': i.expenseSubCategoryId
        }
        return obj;
    });

    const getExpenseById = () => {
        if (ExpenseListId !== '' && ExpenseListId !== null) {
            const sendData = new FormData();
            sendData.append('expenseId', ExpenseListId);
            axios({
                method: "POST",
                url: get_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setexpenseCategoryId(res.data.data.expenseCategoryId);
                    setexpenseAmount(res.data.data.expenseAmount);
                    setexpenseDetails(res.data.data.expenseDetails);
                    setexpenseDate(res.data.data.expenseDate);
                    setexpenseCategoryName(res.data.data.expenseCategory);
                    setRecurring(res.data.data.recurring)
                    setRecurringType(res.data.data.recurringType)
                    setexpenseSubCategoryName(res.data.data.expenseSubCategory)
                    setexpenseSubCategoryId(res.data.data.expenseSubCategoryId)
                    setCompanyName(res.data.data.companyName)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    useEffect(() => {
        getExpenseById();
    }, [ExpenseListId]);


    const handleAddExpense = () => {
        if (ExpenseListId !== '' && ExpenseListId !== null) {
            const sendData = new FormData();
            sendData.append('expenseCategoryId', expenseCategoryId);
            sendData.append('expenseSubCategoryId', expenseSubCategoryId);
            sendData.append('expenseAmount', expenseAmount);
            sendData.append('expenseDetails', expenseDetails);
            sendData.append('expenseId', ExpenseListId);
            sendData.append('expenseDate', expenseDate);
            sendData.append('recurring', recurring);
            sendData.append('recurringType', recurring === 'Yes' ? recurringType : '');
            sendData.append('expenseSubCategory', expenseSubCategoryName);
            sendData.append('companyName', companyName);
            axios({
                method: "POST",
                url: update_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                    setExpenseListId('');
                }
            }).catch(err => {
                console.log(err)
            });
    }
}



    return (
        <Dialog onClose={handleClose} open={openExpenseListDialog} fullWidth>
            <DialogTitle sx={{borderBottom:'1px solid silver'}}>
                UPDATE EXPENSE
            </DialogTitle>
            <DialogContent>
                <Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Company Name</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            fullWidth
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            variant="outlined"
                        />
                    </Box>
                    </Grid>
                    <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Comments</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            fullWidth
                            value={expenseDetails}
                            onChange={(e) => setexpenseDetails(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            variant="outlined"
                        />
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Expense Category</Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            fullWidth
                            value={Categoryoptions.find((option) => option.id === expenseCategoryId) || ''}
                            onChange={(event, value) => {
                                if (value) {
                                    setexpenseCategoryId(value.id);
                                } else {
                                    setexpenseCategoryId(''); // Set to an appropriate default value when nothing is selected
                                }
                            }}
                            options={Categoryoptions}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Expense Sub-Category</Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            fullWidth
                            freeSolo
                            value={SubCategoryoptions.find((option) => option.label === expenseSubCategoryName) || ''}
                            onChange={(event, value) => {
                                    setexpenseSubCategoryName(value?.label);
                                    setexpenseSubCategoryId(value?.id)
                            }}
                            options={ SubCategoryoptions}
                            renderInput={(params) => <TextField  {...params} />}
                        />
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Amount</Typography>
                        <TextField
                        id="outlined-basic"
                        size='small'
                        fullWidth
                        value={expenseAmount}
                        onChange={(e) => {
                        const newValue = e.target.value;
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                        setexpenseAmount(newValue);
                        } else {
                        setexpenseAmount('');
                        }
                        }}
                        variant="outlined"
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Recurring</Typography>
                        <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      value={recurring}
                      fullWidth
                      key={input}
                      onChange={(index, value) => setRecurring(value)}
                      options={['Yes', 'No']}
                      renderInput={(params) => <TextField {...params} required size='small' />}
                    />
                    </Box>
                    </Grid>
                  
                       
                    {    recurring === 'Yes' && 
                          <Grid item xs={12} sm={12} md={6} lg={6}>                
                        <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Recurring Type</Typography>
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size='medium'
                        value={recurringType}
                        fullWidth
                        key={input}
                        onChange={(index, value) => setRecurringType(value)}
                        options={['Monthly', 'Yearly']}
                        renderInput={(params) => <TextField {...params} required size='small' />}
                        />
                        </Box>
                        </Grid>
                        }
 
                  
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Date</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            type='date'
                            InputLabelProps={{
                                shrink:true
                            }}
                            fullWidth
                            value={expenseDate}
                            onChange={(e) => setexpenseDate(e.target.value)}
                            variant="outlined"
                        />
                    </Box>
                    </Grid>
                </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
                <Button onClick={handleAddExpense} variant='outlined' color='success'>UPDATE</Button>
            </DialogActions>
        </Dialog>
    )
}

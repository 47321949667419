

const labels = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MonthlyLabels = [
  'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

const WeeklyLabels = [
  'Day1', 'Day2', 'Day3', 'Day4', 'Day5', 'Day6', 'Day7',
];

const CategoryLabels = [
  'Home and Furniture', 'Technology', 'Sports and Leisure', 'Health and Beauty', 'Baby and Nursery', 'Automobile', 'Men and Women','Toys and Games'
];

const TodayLabels = ['Today'];

const backgroundColor = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(54, 162, 235)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
]
const processbackgroundColor = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(54, 162, 235)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  '#800000',
  '#A52A2A',
]
const borderColor = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)'
]
const procesborderColor = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  'rgb(201, 203, 207)',
  '#800000',
  '#A52A2A',
]



// admin Panel

const orderTable = [
    {
    title:'Order Id',
    filter: Boolean(true)
    },
  {
    title: 'Order Number',
    filter: Boolean(true)
  },
    {
    title:'Customer Id',
    filter: Boolean(true)
    },
    {
    title:'Customer Name',
    },
    {
    title:'Email',
    },
    {
    title:'Order Cost',
    },
    {
    title:'Order Time',
    },
    {
    title:'Payment Option',
    },
  {
    title: 'Update Order Status',
  },
]


const userTable = [
 {
   title:'Customer Id',
    filter: Boolean(true)
  },
  {
   title:'First Name',
  },
  {
   title:'Last Name',
  },
  {
   title:'Email',
   filter: Boolean(true)
  },  
    {
   title:'Mobile',
  }, 
  {
   title:'User Address',
  },
  {
   title:'Province',
  },
      {
   title:'Total Order',
  },
]


const productsTable = [
  {
    title: 'Product',
  },
  {
   title:'Product Id',
    filter: Boolean(true)
  },
  {
   title:'Category Name',
  },
  {
   title:'Product Name',
    filter: Boolean(true)
  },

    {
   title:'Product Price',
  },
  {
    title: 'Inventory(Qty)',
  },
  {
    title:'Color Qty',
    colspan:2
  },
    {
   title:'Discount Percent',
  },
  {
   title:'Product LeadTime',
  },
]

const accessTable = [
  {
    title:'AdminId'
  },
  {
    title:'Admin Name'
  },
  {
    title: 'Role'
  },
  {
    title:'Email'
  },
  {
    title:'Update'
  },
]

const updateproductsTable = [
  {
    title: 'Product',
  },
  {
    title: 'Product Id',
  },
  {
    title: 'Category Name',
    filter: Boolean(true)
  },
  {
    title: 'Product Name',
    filter: Boolean(true)
  },

  {
    title: 'Update',
  },
]

const DeletedproductsTable = [
  {
    title: 'Product',
  },
  {
    title: 'Product Id',
    filter: Boolean(true)
  },
  {
    title: 'Product Name',
    filter: Boolean(true)
  },

  {
    title: 'Product Price',
  },
  {
    title: 'Product Qty',
  },
  {
    title: 'Discount Percent',
  },
  {
    title: 'productLeadTime',
  },
]


const PurchaseListTable = [
  {
    title: 'Date',
  },
  {
    title: 'Bill No',
    filter: Boolean(true)
  },
  {
    title: 'Supplier',
    filter: Boolean(true)
  },
  {
    title: 'Products',
    filter: Boolean(true)
  },
  {
    title: 'Total Cost',
  },
  {
    title: 'Paid',
  },
  {
    title: 'Actions',
  },
]

const ReturnListTable = [
  {
  title:'Order Number',
    filter: Boolean(true)

  },
  {
    title: 'Ordered Date'
  },
  {
    title:'Return Date'
  },
  {
    title: 'User ID'
  },
  {
    title:'Customer Name',
    filter: Boolean(true)
  },
  {
  title:'Amount'
  },
  {title:'View'}
]

const SaleListTable = [
  {
    title: 'Date',
  },
  {
    title: 'Invoice No',
    filter: Boolean(true)
  },
  {
    title: 'Customer',
    filter: Boolean(true)
  },

  {
    title: 'Total Cost',
  },
  {
    title: 'Paid',
  },
  {
    title: 'Actions',
  },
]

const AllCategoryTable = [
  {
    title: 'Category ID',
  },
  {
    title: 'Category Name',
    filter: Boolean(true)
  },
  {
    title: 'Category Image',
    filter: Boolean(true)
  },
]

const UpdateCategoryTable = [
  {
    title: 'Category ID',
  },
  {
    title: 'Category Name',
  },
  {
    title: 'Category Image',
  },
  {
    title: 'Edit/Delete',
  },
]



const AllVendorTable = [
  {
    title: '#',
  },
  {
    title: 'Vendor Name',
    filter: Boolean(true)
  },
  {
    title: 'Contact Person',
  },
  {
    title: 'Phone',
  },
  {
    title: 'Email',
    filter: Boolean(true)
  },
  {
    title: 'Address',
  },
  {
    title: 'Edit/Delete',
  },
]

const ExpensesTable = [
  {
    title: '#',
  },
  {
    title: 'Expense Category',
    filter: Boolean(true)
  },
  {
    title: 'Amount',
  },
  {
    title: 'Expense Details',
  },
  {
    title: 'Date ',
    filter: Boolean(true)
  },
  {
    title: 'Actions',
  },
]


const ExpensesCategoryTable = [
  {
    title: '#',
  },
  {
    title: 'Expense Category',
    filter: Boolean(true)
  },
  {
    title: 'Amount',
  },
  {
    title: 'Actions',
  },
]

const refundList = [
{
  title : 'Order Number'
},
{
  title: 'Date Of Purchase'
},
{
  title: 'Product Code'
},
  {
    title: 'Amount Paid'
  },
  {
    title: 'Invoice Num'
  },
  {
    title: 'Store Purchase'
  },
  {
    title: 'Online Purchase'
  },
  {
    title: 'Image'
  }, {
    title: 'Requested Return'
  },
  {
    title: 'Return Reason'
  },
  {
    title: 'Requested Replacement'
  },
  {
    title: 'ReplacementReason'
  },
  {
    title: 'Requested Refund'
  },
  {
    title: 'Refund Reason'
  },
  {
    title: 'Other Reason'
  },
]

export {
  labels,TodayLabels , CategoryLabels ,WeeklyLabels,MonthlyLabels, backgroundColor, ReturnListTable ,refundList, accessTable, updateproductsTable , AllVendorTable, ExpensesTable, ExpensesCategoryTable,  productsTable, UpdateCategoryTable , AllCategoryTable, SaleListTable, orderTable, userTable, DeletedproductsTable, 
  procesborderColor, processbackgroundColor, borderColor, PurchaseListTable
};
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import { BaseImageAPI } from '../../../Services/APIService';


export default function ChartPopUpDialog({open, setOpen, report , name, stock}) {
  const center = 'center';
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <React.Fragment>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display='flex' justifyContent='space-between'>
            <Stack spacing={1}>
              <Typography variant='subtitle1' fontWeight={600} color='#616e80'>{name}</Typography>
              <Typography variant='subtitle2' fontWeight={600}>{`Total Products - ${report?.length}`}</Typography>
              <Typography variant='subtitle2' fontWeight={600}>{`Total Stock - ${stock}`}</Typography>
            </Stack>
            <Box onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box></DialogTitle>
        <DialogContent>
        <Box m={3}>
                  <TableContainer sx={{ borderRadius: 2 }} >
                  <Table sx={{ borderRadius: 2 }}>
                      <TableHead sx={{ whiteSpace: 'nowrap' }}>
                          <TableRow>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Product</TableCell>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Product Name</TableCell>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Stock Qty</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                   {
                       report && report.map((item) => 
                        <TableRow>
                           <TableCell sx={{ textAlign:'center'}}>
                          <img style={{ width: 100, height: 100 }} src={`${BaseImageAPI}${item.mainImage}`} alt='mainImage' />
                            {item.mainImage}
                            </TableCell>
                          <TableCell sx={{ textAlign:'center'}}>{item.productName}</TableCell>
                          <TableCell sx={{ textAlign:'center'}}>{item.stockQty}</TableCell>
                        </TableRow>
                      )}
                      </TableBody>
                  </Table>
              </TableContainer>
              </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid, Button, Card } from '@mui/material';
import { getmainproducts, get_allproducts, get_all_deleted_products, BaseImageAPI, get_Request, get_all_returns_refunds } from '../../Services/APIService';
import { DeletedproductsTable, RequestListTable, orderTable, productsTable, refundList } from '../../Variables/Variables';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function ReturnRequestList() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [RequestList, setRequestList] = useState([]);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const sendData = new FormData()
        sendData.append('refundId' , '')
        axios({
            method: "POST",
            url: get_all_returns_refunds,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setRequestList(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }, []);


    return (
        <Box py={2}>

            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
          
                  
                        <Grid container>
                            <TableContainer sx={{ border: '1px solid silver' }} >

                                <Table size='small' bgcolor='#eff8f2'>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                            {
                                                refundList.map((i, index) => {
                                                    return (
                                                        <TableCell sx={{
                                                            textAlign: 'center', color: 'white', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',
                                                        }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {i.title}
                                                                </Box>
                                                                <Box>{
                                                                    i.filter &&
                                                                    <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                                }
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>


                                    <TableBody>

                                        {
                                            RequestList.filter((data) => FilterData(data, search, {
                                                searchFeildOne: data.orderNum,
                                                searchFeildTwo: data.dateOfPurchase,
                                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                                return (
                                                    <TableRow key={i.orderNum} sx={{
                                                        borderBottom: '1px solid silver',
                                                        '& .MuiTableCell-root': {
                                                            height: '20px',
                                                        }
                                                    }}>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.orderNum}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.dateOfPurchase}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.productCode}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.amountPaid}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.invoiceNum}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.isStorePurchase === '0' ? 'NO' : 'YES'}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.isOnlinePurchase === '0' ? 'NO' : 'YES'}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>
                                                            
                                                                <Box display='flex' flexDirection='row' gap={1} flexWrap='wrap' width={300}>
                                                                {
                                                                i.images.map((i) => {
                                                                    return (                          
                                                                    <img src={i.transactionImage} style={{ width: 80, height: 80, objectFit: 'contain' }} />                             
                                                                    )
                                                                })}
                                                                </Box>
                                                     
                                                    </TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.isRequestReturn === '0' ? 'NO' : 'YES'}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.requestReturnReason}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.isRequestReplacement === '0' ? 'NO' : 'YES'}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.requestReplacementReason}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.isRequestRefund === '0' ? 'NO' : 'YES'}</TableCell>

                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.requestRefundReason}</TableCell>
                                                        <TableCell sx={{
                                                            textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                            borderRight: '1px solid silver',
                                                        }}>{i.returnReason}</TableCell>

                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={RequestList.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage='Rows'
                            />
                        </Grid>
                   
              
            </Box>
        </Box>
    );
}